import config from "src/config";
import createClient, { FetchResponse, Middleware } from "openapi-fetch";
import { paths } from "./generated/openapi"; // generated from openapi-typescript

import { ROUTES } from "../lite/DepictLiteRouter-ROUTES";
import { posthog } from "posthog-js";

const authMiddleware = (getAccessToken: () => Promise<string>): Middleware => {
  return {
    onRequest: async (req) => {
      const accessToken = await getAccessToken();
      req.headers.set("Authorization", `Bearer ${accessToken}`);
      return req;
    },
    onResponse: async (res) => {
      if (
        res.status === 403 &&
        window.location.pathname !== ROUTES.UNAUTHORIZED
      ) {
        window.location.href = ROUTES.UNAUTHORIZED;
      }
      return res;
    },
  };
};

export const getAuthorizedApi = (getAccessToken: () => Promise<string>) => {
  const sessionId = posthog.get_session_id();
  const client = createClient<paths>({
    baseUrl: config.apiClient.baseUrl,
    headers: {
      ...(sessionId && { "PostHog-Session-ID": sessionId }),
    },
  });
  client.use(authMiddleware(getAccessToken));
  return client;
};

export const getData = <T, O, Media extends `${string}/${string}`>(
  response: FetchResponse<T, O, Media>
): Required<FetchResponse<T, O, Media>>["data"] => {
  if (response.data === undefined) {
    if (response.error) {
      throw new Error(JSON.stringify(response.error));
    }
    throw new Error("No data");
  }
  return response.data;
};

export const throwErrorIfAny = <T, O, Media extends `${string}/${string}`>(
  response: FetchResponse<T, O, Media>
) => {
  if (response.error) {
    throw new Error(JSON.stringify(response.error));
  }
};
