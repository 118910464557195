import { css, Global, useTheme } from "@emotion/react";
import { useIsDarkMode } from "./helpers/hooks/app/useIsDarkMode";

export default function GlobalStyles() {
  const theme = useTheme();
  const isDark = useIsDarkMode();

  return (
    <Global
      styles={css`
        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        html,
        body {
          height: 100%;
        }

        body {
          margin: 0; // 1
          overflow-x: hidden;
          -webkit-text-size-adjust: 100%;
          -webkit-tap-highlight-color: rgba(black, 0);
        }

        #root {
          // min-height: 100vh;

          &:not(.lite-app) {
            height: 100%;
          }

          &.lite-app {
            // Need to have at least viewport height so ag grid works
            flex-grow: 1;
          }
        }

        body:has(#root.lite-app) {
          display: flex;
          flex-direction: column;
        }

        // Fixes autoscroll with dnd

        html {
          scroll-behavior: auto !important;
        }

        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        p {
          margin: 0;
        }

        // Default background color and fonts, so text and icons without styling look good (also in darkmode)
        // Set to <body> so it also applies to portals like modals. Set it from this file instead of DepictLiteGlobalLayout because this also applies to the plan selector.

        body {
          background: ${theme.colors.bg.shopify};
          color: ${theme.colors.text["strong-950"]};
          font-feature-settings:
            "ss11" on,
            "cv09" on,
            "liga" off,
            "calt" off;

          /* Paragraph/Medium */
          font-family: Inter, Helvetica, sans-serif;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: -0.176px;
        }

        :root {
          // Update scrollbars and native form controls to match the theme
          color-scheme: ${isDark ? "dark" : "light"};
        }

        dialog {
          &::backdrop {
            background: rgba(0, 0, 0, 0);
            backdrop-filter: blur(0);
            transition: all 0.3s allow-discrete;
          }

          &[open]::backdrop {
            background: rgba(2, 13, 23, ${isDark ? 0.48 : 0.24});
            backdrop-filter: blur(5px);
            @starting-style {
              background: rgba(0, 0, 0, 0);
              backdrop-filter: blur(0);
            }
          }
        }

        :focus-visible {
          outline: 1px auto ${theme.colors.stroke["strong-950"]};
          box-shadow: ${theme.shadows["important-focus"]};
        }
      `}
    />
  );
}
