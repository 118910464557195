import { Navigate } from "react-router";
import useOptionalMerchant from "src/helpers/hooks/app/useOptionalMerchant";
import { buildPathWithMerchantId } from "../helpers/buildPathWithMerchantId";
import { ROUTES } from "../DepictLiteRouter-ROUTES";
import { TRIGGER_INSTALL_PATH_MERCHANT_ID } from "../views/GettingStarted/Ingestion/IngestionStep";

export default function GettingStartedRedirect() {
  const { merchant } = useOptionalMerchant();

  if (!merchant) {
    // We are not loading, yet no merchant exists. Try installing. Simply a fast path, GettingStartedCompletedBoundary would handle this as well.
    return (
      <Navigate
        to={buildPathWithMerchantId(
          ROUTES.GETTING_STARTED,
          TRIGGER_INSTALL_PATH_MERCHANT_ID
        )}
      />
    );
  }

  // We have a merchant, route to dashboard.
  // The Dashboard is covered by additional boundaries that will redirect to the correct spot in the Getting Started flow if necessary
  return (
    <Navigate
      replace
      to={buildPathWithMerchantId(ROUTES.COLLECTIONS, merchant.id)}
    />
  );
}
