import { ActiveSubscriptionDTO } from "../../api/types";
import { PlanProps } from "../../alignUI/Plans/Plans";
import { CheckCircle, MinusCircle } from "@phosphor-icons/react";
import { useTheme } from "@emotion/react";

export type PlanType =
  | Extract<ActiveSubscriptionDTO["name"], "Basic" | "Essential" | "Pro">
  | "free";

/**
 * IF YOU CHANGE THESE,
 * MAKE SURE YOU CHANGE THE CONSTANTS IN
 * /common/common/shopify/subscription.py
 */

export const planTypeToPrice: Record<PlanType, number> = {
  free: 0,
  Basic: 50,
  Essential: 250,
  Pro: 500,
};

export const planTypeToAnnualPrice: Record<PlanType, number> = {
  free: 0,
  Basic: 500,
  Essential: 2500,
  Pro: 5000,
};

export const planNameById: Record<PlanType, string> = {
  free: "Free",
  Basic: "Basic",
  Essential: "Essentials",
  Pro: "Pro",
};

type PlanFeature =
  | "multi_store"
  | "one_store"
  | "unlimited_collections"
  | "limited_collections"
  | "boost_and_pinning"
  | "analytics"
  | "content_blocks";

const featureTextById: Record<PlanFeature, string> = {
  multi_store: "Unlimited stores",
  one_store: "1 store",
  unlimited_collections: "Unlimited collections",
  limited_collections: "3 Collections",
  boost_and_pinning: "Boost & Pinning",
  analytics: "Analytics",
  content_blocks: "Content blocks",
};

const featureIdsPerPlan: Record<PlanType, PlanFeature[]> = {
  free: [
    "limited_collections",
    "boost_and_pinning",
    "analytics",
    "content_blocks",
  ],
  Basic: [
    "unlimited_collections",
    "boost_and_pinning",
    "analytics",
    "content_blocks",
  ],
  Essential: [
    "unlimited_collections",
    "boost_and_pinning",
    "analytics",
    "content_blocks",
  ],
  Pro: [
    "multi_store",
    "unlimited_collections",
    "boost_and_pinning",
    "analytics",
    "content_blocks",
  ],
};

const showMinusIconOn: PlanFeature[] = ["one_store", "limited_collections"];

export const useStyledFeatureList = (
  plan: PlanType,
  withColor: boolean = false
): PlanProps["features"] => {
  const theme = useTheme();
  const featureIds = featureIdsPerPlan[plan];
  return featureIds.map((featureId) => ({
    icon: showMinusIconOn.includes(featureId) ? MinusCircle : CheckCircle,
    text: featureTextById[featureId],
    color:
      withColor && !showMinusIconOn.includes(featureId) ? "blue" : undefined,
  }));
};

export const useDiffFeatureList = (
  fromPlan: PlanType,
  toPlan: PlanType
): [PlanProps["features"], PlanProps["features"]] => {
  const theme = useTheme();
  const isUpgrade = planTypeToPrice[toPlan] > planTypeToPrice[fromPlan];
  const fromFeatureIds = featureIdsPerPlan[fromPlan];
  const toFeatureIds = featureIdsPerPlan[toPlan];

  // Stable sort features so any diff is first
  fromFeatureIds.sort((a, b) => {
    const aIsDiff = !toFeatureIds.includes(a);
    const bIsDiff = !toFeatureIds.includes(b);
    return aIsDiff === bIsDiff ? 0 : aIsDiff ? -1 : 1;
  });
  toFeatureIds.sort((a, b) => {
    const aIsDiff = !fromFeatureIds.includes(a);
    const bIsDiff = !fromFeatureIds.includes(b);
    return aIsDiff === bIsDiff ? 0 : aIsDiff ? -1 : 1;
  });

  const styledFromFeatures: PlanProps["features"] = fromFeatureIds.map(
    (featureId) => {
      const isDiff = !toFeatureIds.includes(featureId);

      return {
        icon: isDiff ? MinusCircle : CheckCircle,
        text: featureTextById[featureId],
        color: isDiff ? "red" : undefined,
      };
    }
  );
  const styledToFeatures: PlanProps["features"] = toFeatureIds.map(
    (featureId) => {
      const isDiff = !fromFeatureIds.includes(featureId);

      return {
        icon: isDiff ? CheckCircle : CheckCircle,
        text: featureTextById[featureId],
        color: isDiff ? "blue" : undefined,
      };
    }
  );
  return [styledFromFeatures, styledToFeatures];
};
