import { css } from "@emotion/react";
import { PropsWithChildren, Children, useState, useRef } from "react";
import {
  motion,
  useAnimationFrame,
  useMotionValue,
  useTransform,
} from "framer-motion";

export type CarouselProps = PropsWithChildren<{
  className?: string;
  delay?: number;
}>;

export function Carousel({ className, delay = 5000, children }: CarouselProps) {
  const [index, setIndex] = useState(0);
  const childrenArray = Children.toArray(children);
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
      className={className}
    >
      <div>
        <StepSwitcher
          index={index}
          onChange={setIndex}
          count={childrenArray.length}
          delay={delay}
        />
      </div>
      <div
        css={css`
          overflow: hidden;
          width: 100%;
        `}
      >
        <motion.div
          css={css`
            display: grid;
            grid-template-columns: repeat(${childrenArray.length}, 100%);
            justify-items: center;
          `}
          animate={{ x: `-${index * 100}%` }}
          transition={{
            duration: 1,
            ease: [0.56, 0.03, 0.12, 1.0],
          }}
        >
          {children}
        </motion.div>
      </div>
    </div>
  );
}

function StepSwitcher({
  index,
  count,
  delay,
  onChange,
}: {
  count: number;
  index: number;
  delay: number;
  onChange: (index: number) => void;
}) {
  return (
    <div
      css={css`
        padding: 16px;
        display: flex;
        gap: 16px;

        overflow: hidden;
      `}
    >
      {Array.from({ length: count }).map((_, i) => (
        <motion.button
          key={i}
          animate={{ width: i === index ? 48 : 8 }}
          css={(theme) => css`
            all: unset;
            width: 8px;
            height: 8px;
            border-radius: 9px;
            background: ${theme.colors.bg["soft-200"]};
            cursor: pointer;
            overflow: hidden;
          `}
          onClick={() => onChange(i)}
        >
          {i === index && (
            <Timer
              delay={delay}
              onNext={() => {
                if (index === count - 1) {
                  onChange(0);
                } else {
                  onChange(index + 1);
                }
              }}
            />
          )}
        </motion.button>
      ))}
    </div>
  );
}

function Timer({ onNext, delay }: { onNext: () => void; delay: number }) {
  const onNextFired = useRef(false);
  useAnimationFrame((time) => {
    if (onNextFired.current) return;
    if (time > delay) {
      onNextFired.current = true;
      onNext();
    } else {
      width.set((time / delay) * 100);
    }
  });
  const width = useMotionValue(0);
  const widthPercent = useTransform(width, (value) => `calc(8px + ${value}%)`);

  return (
    <motion.div
      css={(theme) => css`
        background: ${theme.colors.bg["strong-800"]};
        border-radius: 9px;
        height: 8px;
        margin-left: -8px;
      `}
      style={{ width: widthPercent }}
    />
  );
}
