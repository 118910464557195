import { css } from "@emotion/react";

// Style shazas for clamping text to parent width without having to hard code the width
export const clampTextToParentWidthStyle = css`
  word-wrap: anywhere;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  word-break: break-word;
  -webkit-line-clamp: 1;
`;
