// AlignUI Notification v0.0.0

import * as React from "react";
import * as NotificationPrimitives from "@radix-ui/react-toast";
import { css, Theme } from "@emotion/react";

import * as Alert from "src/alignUI/Alert/Alert";
import { motion } from "framer-motion";

const NotificationProvider = NotificationPrimitives.Provider;
const NotificationAction = NotificationPrimitives.Action;

const viewportStyles = (theme: Theme) => css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  max-height: 100vh;
  width: 100%;
  flex-direction: column;
  gap: 1.25rem;
  padding: 16px;

  @media (min-width: ${theme.breakpoints.sm}px) {
    left: 50%;
    max-width: 438px;
    transform: translateX(-50%);
  }
`;

const NotificationViewport = React.forwardRef<
  React.ComponentRef<typeof NotificationPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof NotificationPrimitives.Viewport>
>(({ className, ...rest }, forwardedRef) => (
  <NotificationPrimitives.Viewport
    ref={forwardedRef}
    css={viewportStyles}
    className={className}
    {...rest}
  />
));
NotificationViewport.displayName = "NotificationViewport";

type NotificationProps = React.ComponentPropsWithoutRef<
  typeof NotificationPrimitives.Root
> &
  React.ComponentPropsWithoutRef<typeof Alert.Alert>;

const rootStyles = css`
  &[data-swipe="cancel"] {
    transform: translateX(0);
  }
  &[data-swipe="end"] {
    transform: translateX(var(--radix-toast-swipe-end-x));
    animation: out;
  }
  &[data-swipe="move"] {
    transform: translateX(var(--radix-toast-swipe-move-x));
    transition: none;
  }
`;

const Notification = React.forwardRef<
  React.ComponentRef<typeof NotificationPrimitives.Root>,
  NotificationProps
>(
  (
    { className, status, styling = "filled", text, ...rest }: NotificationProps,
    forwardedRef
  ) => {
    return (
      <NotificationPrimitives.Root
        ref={forwardedRef}
        css={rootStyles}
        className={className}
        asChild
        {...rest}
      >
        <motion.div
          initial={{ opacity: 0, y: -128 }}
          animate={{ opacity: 1, y: 0 }}
          css={css`
            width: 100%;
          `}
        >
          <Alert.Alert
            styling={styling}
            status={status}
            size="large"
            text={text}
            css={css`
              width: 100%;
            `}
          />
        </motion.div>
      </NotificationPrimitives.Root>
    );
  }
);
Notification.displayName = "Notification";

export {
  Notification as Root,
  NotificationProvider as Provider,
  NotificationAction as Action,
  NotificationViewport as Viewport,
  type NotificationProps,
};
