import { useTheme } from "@emotion/react";

export const DepictSpinner = () => {
  const theme = useTheme();

  return (
    <svg
      width="42"
      height="42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
    >
      <g clipPath="url(#clip0_114_224)">
        <path
          d="M38.75 52.813H13.437A8.438 8.438 0 0 0 5 61.25v25.313A8.438 8.438 0 0 0 13.438 95H38.75a8.438 8.438 0 0 0 8.438-8.438V61.25a8.438 8.438 0 0 0-8.438-8.438Z"
          fill={theme.colors.bg["strong-950"]}
          style={{ animation: "1s ease-in-out 1s infinite fade" }}
        />
        <path
          d="M86.563 69.688H61.25a8.438 8.438 0 0 0-8.438 8.437v8.438A8.438 8.438 0 0 0 61.25 95h25.313A8.438 8.438 0 0 0 95 86.562v-8.437a8.438 8.438 0 0 0-8.438-8.438Z"
          fill={theme.colors.bg["strong-950"]}
          style={{ animation: "1s ease-in-out 750ms infinite fade" }}
        />
        <path
          d="M38.75 5H13.437A8.438 8.438 0 0 0 5 13.438V38.75a8.438 8.438 0 0 0 8.438 8.438H38.75a8.438 8.438 0 0 0 8.438-8.438V13.437A8.438 8.438 0 0 0 38.75 5Z"
          fill={theme.colors.bg["strong-950"]}
          style={{ animation: "1s ease-in-out 250ms infinite fade" }}
        />
        <path
          d="M86.563 5H61.25a8.438 8.438 0 0 0-8.438 8.438v42.187a8.438 8.438 0 0 0 8.438 8.438h25.313A8.438 8.438 0 0 0 95 55.624V13.437A8.438 8.438 0 0 0 86.562 5Z"
          fill={theme.colors.bg["strong-950"]}
          style={{ animation: "1s ease-in-out 500ms infinite fade" }}
        />
      </g>
      <defs>
        <clipPath id="clip0_114_224">
          <path
            fill={theme.colors.text["white-0"]}
            transform="translate(5 5)"
            d="M0 0h90v90H0z"
          />
        </clipPath>
        <style>{`@keyframes fade{0 %,50%,75%,100%{opacity:1}25%{opacity:0.3}}`}</style>
      </defs>
    </svg>
  );
};
