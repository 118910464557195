import { createRoutesFromElements, Route } from "react-router";
import { ROUTES } from "./DepictLiteRouter-ROUTES";
import DepictLiteGlobalLayout from "./views/DepictLiteGlobalLayout/DepictLiteGlobalLayout";
import { GettingStartedSteps } from "./views/GettingStarted/WizardLayout";
import IngestionStep from "./views/GettingStarted/Ingestion/IngestionStep";
import Collections from "./views/Collections/Collections";
import MerchantBoundary from "./boundaries/MerchantBoundary";
import MultiStoreBoundary from "./boundaries/MultiStoreBoundary";
import GettingStartedRedirect from "./boundaries/GettingStartedRedirect";
import NavigateToCollections from "./boundaries/NavigateToCollections";
import GettingStartedCompletedBoundary from "./boundaries/GettingStartedCompletedBoundary";
import ErrorPage from "./views/ErrorPage/ErrorPage";
import Unauthorized from "./views/ErrorPage/Unauthorized";
import NotFound from "./views/ErrorPage/NotFound";
import { getCreateBrowserRouter } from "src/monitoring";

export function DepictLiteRouter(baseName: string) {
  const createBrowserRouter = getCreateBrowserRouter();

  return createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<ErrorPage />}>
        <Route
          path={ROUTES.NO_MERCHANTS}
          lazy={() => import("./views/NoMerchants")}
        />
        <Route path={ROUTES.UNAUTHORIZED} element={<Unauthorized />} />
        <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />

        <Route element={<MerchantBoundary />}>
          <Route element={<MultiStoreBoundary />}>
            <Route index element={<GettingStartedRedirect />} />
            <Route
              path={ROUTES.DEFAULT_REDIRECT}
              element={<NavigateToCollections />}
            />

            <Route path={ROUTES.GETTING_STARTED} element={<IngestionStep />} />
            <Route
              id={GettingStartedSteps.Step1}
              path={ROUTES.GETTING_STARTED_STEP_1}
              lazy={() => import("./views/GettingStarted/Steps/Columns")}
            />
            <Route
              id={GettingStartedSteps.Step2}
              path={ROUTES.GETTING_STARTED_STEP_2}
              lazy={() =>
                import(
                  "./views/GettingStarted/Steps/SelectCollection/SelectCollection"
                )
              }
            />

            <Route element={<GettingStartedCompletedBoundary />}>
              <Route element={<DepictLiteGlobalLayout />}>
                {/* This is temporary such that people lands on collections if they kept the dashboard url */}
                <Route
                  path={"/:merchantId/dashboard"}
                  element={<NavigateToCollections />}
                />
                <Route path={ROUTES.COLLECTIONS} Component={Collections} />
                <Route
                  path={ROUTES.COLLECTION}
                  lazy={() => import("./views/Collection/Collection")}
                />
                <Route
                  path={ROUTES.GUIDES}
                  lazy={() => import("./views/Guides/Guides")}
                />
                <Route
                  path={ROUTES.SETTINGS}
                  lazy={() => import("./views/Settings/Settings")}
                />
                <Route
                  path={ROUTES.MULTI_STORE_CONNECTIONS}
                  lazy={() =>
                    import("./views/Settings/MultiStoreCollectionMappingView")
                  }
                />
                {false && (
                  <Route
                    path={ROUTES.MULTI_STORE_STORES}
                    lazy={() => import("./views/Settings/MultiStore/Stores")}
                  />
                )}
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    ),
    {
      basename: baseName,
    }
  );
}
