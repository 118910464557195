import { Navigate, Outlet } from "react-router";
import useOptionalMerchant from "src/helpers/hooks/app/useOptionalMerchant";
import { AdminAPIClientProvider } from "src/helpers/hooks/useAdminAPI";
import { StorefrontAPIClientProvider } from "src/helpers/hooks/useStorefrontAPIClient";
import { useMaybeAppBridge } from "src/helpers/shopify/useMaybeAppBridge";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import { ROUTES } from "../DepictLiteRouter-ROUTES";

export default function MerchantBoundary() {
  const optionalMerchant = useOptionalMerchant();
  const appBridge = useMaybeAppBridge();

  if (optionalMerchant.loading && !optionalMerchant.merchant) {
    // We are still loading the merchant, show splash screen
    return <SplashScreen />;
  }

  if (!optionalMerchant.merchant) {
    // Merchant loaded, but there is none. Should only happen in embedded mode, meaning they need to install. Handled in boundaries below.
    if (!appBridge?.environment?.embedded) {
      // Superuser trying to access a non-existent merchant
      return <Navigate to={ROUTES.NO_MERCHANTS} />;
    }
  }

  return (
    <StorefrontAPIClientProvider>
      <AdminAPIClientProvider>
        <Outlet />
      </AdminAPIClientProvider>
    </StorefrontAPIClientProvider>
  );
}
