import { Collection } from "./CollectionTable.types";
import { css, useTheme } from "@emotion/react";
import { CollectionImage } from "../CollectionImage";
import { ActionButtons } from "./ActionButtons";
import { Link } from "react-router";
import { BadgeGroup } from "../../../components/CollectionBadges";
import React from "react";
import {
  LinkBox,
  LinkOverlay,
} from "../../../../alignUI/LinkOverlay/LinkOverlay";
import { clampTextToParentWidthStyle } from "../../../styles/clampTextToParentWidthStyle";
import { TextPlaceholder } from "../../../../alignUI/Placeholder/Placeholder";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";

export function CollectionGrid({
  collections,
  isLoading,
  loadMoreRef,
  onUnPublishDepict,
  setSelectedCollectionId,
}: {
  collections: Collection[];
  isLoading: boolean;
  loadMoreRef: (index: number) => (element: HTMLElement | null) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
}) {
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 16px;
        align-self: stretch;
        flex-wrap: wrap;

        @media (max-width: 555px) {
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }
      `}
    >
      {collections.map((collection, index) => (
        <CollectionCard
          key={collection.id}
          collection={collection}
          setSelectedCollectionId={setSelectedCollectionId}
          onUnPublishDepict={onUnPublishDepict}
          isLoading={isLoading}
          loadMoreRef={loadMoreRef(index)}
        />
      ))}
    </div>
  );
}

function CollectionCard({
  collection,
  setSelectedCollectionId,
  onUnPublishDepict,
  isLoading,
  loadMoreRef,
}: {
  collection: Collection;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  isLoading: boolean;
  loadMoreRef?: (element: HTMLElement | null) => void;
}) {
  const theme = useTheme();
  const { merchantId } = useMerchantId();

  return (
    <LinkBox
      ref={loadMoreRef}
      className={"collection-card"}
      css={css`
        display: flex;
        padding: 4px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        border-radius: 12px;
        border: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["white-0"]};
        position: relative;

        .collection-link {
          text-decoration: none;
        }

        &:has(.grid-action-buttons:hover) {
          background: ${theme.colors.bg["white-0"]};
        }

        &:hover,
        &:has([aria-expanded="true"]) {
          .grid-action-buttons {
            visibility: visible;
          }

          background: ${theme.colors.bg["weak-50"]};
          cursor: pointer;
          text-decoration: underline;
        }
      `}
    >
      <CollectionImage
        showPlaceholder={isLoading}
        coverImages={collection.coverImages}
        productImages={collection.productImages}
        imageLoadingResolution={
          collection.coverImages || collection.productImages?.length === 1
            ? "large"
            : "medium"
        }
      />

      <div
        css={css`
          display: flex;
          gap: 2px;
          flex-direction: column;
          position: absolute;
          bottom: 52px;
          left: 8px;
          z-index: 1;
        `}
      >
        {!isLoading && <BadgeGroup badges={collection.badges} />}
      </div>

      <div
        css={css`
          display: flex;
          padding: 6px 4px;
          align-items: center;
          gap: 14px;
          align-self: stretch;
          justify-content: space-between;
        `}
      >
        <LinkOverlay asChild>
          <Link
            className="collection-link"
            to={`/${merchantId}/collections/${collection.id}`}
            title={collection.title}
            css={[
              css`
                ${theme.typography["label-s"]}
                color: ${theme.colors.text["strong-950"]};
              `,
              clampTextToParentWidthStyle,
            ]}
          >
            {isLoading ? (
              <TextPlaceholder>Loading collections</TextPlaceholder>
            ) : (
              collection.title
            )}
          </Link>
        </LinkOverlay>

        {!isLoading && (
          <ActionButtons
            collection={collection}
            onUnPublishDepict={onUnPublishDepict}
            setSelectedCollectionId={setSelectedCollectionId}
            className="grid-action-buttons"
          />
        )}
      </div>
    </LinkBox>
  );
}
