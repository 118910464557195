import { css, SerializedStyles, useTheme } from "@emotion/react";
import { Link, LinkProps } from "@radix-ui/themes";
import { Size, Style } from "./DepictLink.types";
import { colorPalette } from "src/theme";
import React from "react";

type LinkButtonProps = React.PropsWithChildren<
  Omit<LinkProps, "size" | "underline"> & {
    href?: string;
    underline?: boolean;
    _style?: Style;
    size?: Size;
    disabled?: boolean;
    extraCss?: SerializedStyles;
  }
>;
export const DepictLink = ({
  size = "Small",
  _style: style = "Black",
  underline,
  extraCss,
  children,
  ...propsToForward
}: LinkButtonProps) => {
  const theme = useTheme();

  const color = (
    {
      Gray: theme.colors.text["sub-600"],
      Black: theme.colors.text["strong-950"],
      Primary: theme.colors.primary.base,
      Error: theme.colors.state.error.base,
      White: theme.colors.neutral.static.white,
    } as Record<Style, string>
  )[style];

  const typography = (
    {
      Small: theme.typography["label-xs"],
      Medium: theme.typography["label-s"],
      inherit: css``,
    } as const
  )[size];

  const hoverColor = (
    {
      Gray: theme.colors.text["sub-600"],
      Black: theme.colors.text["strong-950"],
      Primary: theme.colors.primary.darker,
      Error: colorPalette.red[700],
      White: theme.colors.neutral.static.white,
    } as Record<Style, string>
  )[style];

  const focusColor = (
    {
      Gray: theme.colors.text["strong-950"],
      Black: theme.colors.text["strong-950"],
      Primary: theme.colors.primary.base,
      Error: theme.colors.state.error.base,
      White: theme.colors.neutral.static.white,
    } as Record<Style, string>
  )[style];

  const activeStyle = [
    typography,
    css`
      color: ${color};

      gap: 4px;
      display: flex;

      text-decoration-line: ${underline ? "underline" : "none"};
      cursor: pointer;

      &:hover {
        text-decoration-line: underline;
        color: ${hoverColor};
      }

      &:focus {
        text-decoration-line: underline;
        color: ${focusColor};
      }
    `,
    extraCss,
  ];

  const disabledStyle = [
    typography,
    css`
      gap: 4px;
      display: flex;

      color: ${theme.colors.text["disabled-300"]};

      &:hover {
        text-decoration-line: none;
        color: ${theme.colors.text["disabled-300"]};
      }
    `,
    extraCss,
  ];

  const appliedStyle = propsToForward.disabled ? disabledStyle : activeStyle;

  return (
    <Link {...propsToForward} css={appliedStyle}>
      {children}
    </Link>
  );
};
