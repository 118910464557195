import { css } from "@emotion/react";
import React from "react";
import PageHeader from "../../../../alignUI/PageHeader/PageHeader";
import { JimoBell } from "../JimoBell";

export function CollectionsHeader() {
  return (
    <>
      <div
        className="collections-header"
        css={css`
          display: flex;
          padding-bottom: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: stretch;
          gap: 16px;
          align-self: stretch;
          z-index: 11; // So shadow goes above grid below
        `}
      >
        <PageHeader
          title="Collections"
          outerCss={css`
            padding: 0 16px;
            margin: 0;
          `}
          wrapperCss={css`
            display: flex;
            gap: 12px;
          `}
        >
          <JimoBell />
        </PageHeader>
      </div>
    </>
  );
}
