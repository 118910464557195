import config from "src/config";
import { getIdTokenSilently } from "src/lite/auth0";

export async function getAccessToken() {
  try {
    if (shopify.environment.embedded) {
      return await shopify.idToken();
    }
  } catch {
    // shopify global throws an error when it's not available
  }
  if (config.useLocalStorageAccessToken) {
    //Check if local storage has a token and use that instead
    const token = localStorage.getItem("prod_token");
    if (token) {
      return token;
    }
  }
  const idToken = await getIdTokenSilently();
  return idToken;
}
