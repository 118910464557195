import React, { useEffect, useRef, useState } from "react";
import { useContentBlocks } from "../../ContentBlock/useContentBlocks";
import { LiteCollectionProductDto } from "../../../../api/types";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { ProductDuplicate } from "../ProductHighlight/useProductDuplicates";
import {
  getOriginalProductIdForDuplicateProduct,
  getProductIdForDuplicateProduct,
} from "../ProductHighlight/productDuplicateHelpers";

export const dragToSelectAndDeselectClass = "click-to-drag-to-select";

export function useDragToSelect(dndGridRef: React.RefObject<HTMLDivElement>) {
  const {
    selectedItems,
    gridGapPx,
    setSelectedItems,
    occupiedByBlocksOrProducts,
    productCardHeight,
    productsToRender,
    numberOfColumns,
    productDuplicates,
  } = useDragAndDropContext();

  const productDuplicatesRef = useRef(productDuplicates);
  const selectedItemsRef = useRef(selectedItems);
  const [dragSelectingPointerId, setDragSelectingPointerId] = useState<
    number | undefined
  >();
  // Need to make this a ref because we don't want the hook to re-execute when it changes, we just want to be able to get the current value of it
  const occupiedByBlocksOrProductsRef = useRef(occupiedByBlocksOrProducts);

  useEffect(() => {
    occupiedByBlocksOrProductsRef.current = occupiedByBlocksOrProducts;
  }, [occupiedByBlocksOrProducts]);

  useEffect(() => {
    selectedItemsRef.current = selectedItems;
  }, [selectedItems]);

  useEffect(() => {
    productDuplicatesRef.current = productDuplicates;
  }, [productDuplicates]);

  useEffect(() => {
    if (productCardHeight == undefined) return;
    let overlayElement: HTMLDialogElement | undefined;
    let pointerId: number | undefined;
    let startPageX: number | undefined;
    let startPageY: number | undefined;
    let currentClientX: number | undefined;
    let currentClientY: number | undefined;
    let currentScrollX = window.scrollX;
    let invertSelectionOnItems = false;
    let currentScrollY = window.scrollY;
    let selectedAtDragStart: Set<string> | undefined;
    let documentHeightAtStart: number | undefined;
    let documentWidthAtStart: number | undefined;
    let coordinatesOfItems:
      | ReturnType<typeof mapItemsToCoordinates>
      | undefined;

    const { body, documentElement } = document;

    const updateStyling = () => {
      if (!overlayElement || !selectedAtDragStart) return;
      const currentPageX = currentClientX! + currentScrollX;
      const currentPageY = currentClientY! + currentScrollY;
      const { style } = overlayElement;
      const rectLeft = Math.min(startPageX!, currentPageX!);
      const rectTop = Math.min(startPageY!, currentPageY!);
      const rectWidth = Math.min(
        Math.abs(currentPageX - startPageX!),
        // Do not allow the rectangle to become bigger than the viewport and thereby enlarge the viewport
        documentWidthAtStart! - rectLeft
      );
      const rectHeight = Math.min(
        Math.abs(currentPageY - startPageY!),
        // Do not allow the rectangle to become bigger than the viewport and thereby enlarge the viewport
        documentHeightAtStart! - rectTop
      );
      style.left = rectLeft + "px";
      style.top = rectTop + "px";
      // Add scroll value here to current ones so that the overlay stays in the same place while scrolling, without new pointer events coming in
      style.width = rectWidth + "px";
      style.height = rectHeight + "px";

      selectElementsUnderRectangle({
        rectTop,
        rectHeight,
        rectLeft,
        rectWidth,
        selectedItemsRef,
        setSelectedItems,
        coordinatesOfItems: coordinatesOfItems!,
        invertSelectionOnItems,
        selectedAtDragStart,
      });
    };
    const pointerDownHandler = (e: PointerEvent) => {
      if (
        e.button !== 0 ||
        pointerId ||
        e.pointerType !== "mouse" ||
        !(e.target as HTMLElement).matches(`.${dragToSelectAndDeselectClass}`)
      ) {
        return;
      }
      overlayElement = document.createElement("dialog");
      overlayElement.ariaLabel = "Overlay to multi-select products below it";
      overlayElement.classList.add("dnd-drag-to-select-overlay");
      // Listen to native close event on dialog to implement cancelling the selection with esc button
      overlayElement.addEventListener("close", () => {
        stopSelecting();
        // Seems like cancelling a selection with escape doesn't revert to previous selection in apple photos (even when not clicking outside at start of drag) but instead deselects, follow that behavior
        setSelectedItems(new Set());
      });
      body.append(overlayElement);

      startPageX = e.pageX;
      startPageY = e.pageY;
      currentClientX = e.clientX;
      currentClientY = e.clientY;
      pointerId = e.pointerId;
      invertSelectionOnItems = e.shiftKey || e.metaKey || e.ctrlKey;
      selectedAtDragStart = selectedItemsRef.current;
      documentHeightAtStart = Math.max(
        documentElement.scrollHeight,
        body.scrollHeight
      );
      documentWidthAtStart = Math.max(
        documentElement.scrollWidth,
        body.scrollWidth
      );

      // Assume people don't resize the screen during a drag to select
      const rectOfDndGrid = dndGridRef.current!.getBoundingClientRect();
      const dndGridPageX = rectOfDndGrid.left + window.scrollX;
      const dndGridPageY = rectOfDndGrid.top + window.scrollY;

      coordinatesOfItems = mapItemsToCoordinates({
        occupiedByBlocksOrProducts: occupiedByBlocksOrProductsRef.current,
        productsToRender,
        numberOfColumns,
        gridGapPx,
        dndGridPageY,
        dndGridPageX,
        productCardHeight,
        dndGridWidth: rectOfDndGrid.width,
        productDuplicatesRef,
      });

      updateStyling();
      // Very important to call showModal after updateStyling, as the browser will scroll the modal into view and before updateStyling is called the modal is somewhere at the top
      overlayElement.showModal();
      setDragSelectingPointerId(pointerId);
    };
    const pointerMoveHandler = (e: PointerEvent) => {
      if (e.pointerId !== pointerId) return;
      currentClientX = e.clientX;
      currentClientY = e.clientY;
      updateStyling();
    };
    const stopSelecting = () => {
      pointerId = undefined;
      overlayElement?.remove();
      overlayElement = undefined;
      startPageX = undefined;
      startPageY = undefined;
      currentClientX = undefined;
      currentClientY = undefined;
      coordinatesOfItems = undefined;
      selectedAtDragStart = undefined;
      documentHeightAtStart = undefined;
      documentWidthAtStart = undefined;
      invertSelectionOnItems = false;
      setDragSelectingPointerId(undefined);
    };
    const pointerUpHandler = (e: PointerEvent) => {
      if (e.pointerId !== pointerId) return;
      stopSelecting();
    };
    const scrollHandler = () => {
      const x = window.scrollX;
      const y = window.scrollY;
      if (x !== currentScrollX || y !== currentScrollY) {
        currentScrollX = x;
        currentScrollY = y;
        updateStyling();
      }
    };

    window.addEventListener("pointerdown", pointerDownHandler);
    window.addEventListener("pointermove", pointerMoveHandler);
    window.addEventListener("pointerup", pointerUpHandler);
    window.addEventListener("scroll", scrollHandler);
    // Should never be called anyway since we're limiting drag to select to mouses
    window.addEventListener("pointercancel", pointerUpHandler);

    return () => {
      window.removeEventListener("pointerdown", pointerDownHandler);
      window.removeEventListener("pointermove", pointerMoveHandler);
      window.removeEventListener("pointerup", pointerUpHandler);
      window.removeEventListener("pointercancel", pointerUpHandler);
      window.removeEventListener("scroll", scrollHandler);
      stopSelecting();
    };
  }, [
    dndGridRef,
    gridGapPx,
    numberOfColumns,
    productCardHeight,
    productsToRender,
    setSelectedItems,
  ]);

  return dragSelectingPointerId;
}

function selectElementsUnderRectangle({
  rectTop,
  rectHeight,
  rectLeft,
  rectWidth,
  setSelectedItems,
  selectedItemsRef,
  invertSelectionOnItems,
  coordinatesOfItems,
  selectedAtDragStart,
}: {
  rectTop: number;
  rectLeft: number;
  rectWidth: number;
  rectHeight: number;
  selectedItemsRef: React.MutableRefObject<Set<string>>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Set<string>>>;
  coordinatesOfItems: ReturnType<typeof mapItemsToCoordinates>;
  invertSelectionOnItems: boolean;
  selectedAtDragStart: Set<string>;
}) {
  const itemsInSelection = coordinatesOfItems.filter(
    ({ pageBottom, pageLeft, pageTop, pageRight }) =>
      pageTop < rectTop + rectHeight &&
      pageBottom > rectTop &&
      pageLeft < rectLeft + rectWidth &&
      pageRight > rectLeft
  );

  const newSelectedIds = new Set(
    itemsInSelection.flatMap(({ itemIds }) => itemIds)
  );
  const oldItems = selectedItemsRef.current;

  if (invertSelectionOnItems) {
    for (const item of selectedAtDragStart) {
      if (newSelectedIds.has(item)) {
        newSelectedIds.delete(item);
      } else {
        newSelectedIds.add(item);
      }
    }
  }

  nothingChangedEarlyReturn: {
    if (oldItems.size !== newSelectedIds.size) break nothingChangedEarlyReturn;
    for (const newItem of newSelectedIds) {
      if (!oldItems.has(newItem)) {
        break nothingChangedEarlyReturn;
      }
    }
    return;
  }
  // just calling setSelectedItems with the same value causes a rerender and is SUPER expensive (makes selecting laggy all the time instead of just when the selection changes)
  setSelectedItems(newSelectedIds);
}

function mapItemsToCoordinates({
  occupiedByBlocksOrProducts,
  productsToRender,
  numberOfColumns,
  gridGapPx,
  dndGridPageY,
  dndGridPageX,
  productCardHeight,
  dndGridWidth,
  productDuplicatesRef,
}: {
  occupiedByBlocksOrProducts: ReturnType<
    typeof useContentBlocks
  >["occupiedByBlocksOrProducts"];
  productsToRender: LiteCollectionProductDto[];
  numberOfColumns: number;
  gridGapPx: number;
  dndGridPageX: number;
  dndGridPageY: number;
  dndGridWidth: number;
  productCardHeight: number;
  productDuplicatesRef: React.MutableRefObject<ProductDuplicate[]>;
}) {
  let topOffset = dndGridPageY;

  const positions: {
    itemIds: string[];
    pageLeft: number;
    pageRight: number;
    pageTop: number;
    pageBottom: number;
  }[] = [];
  const gridWidthWithoutGaps = dndGridWidth - (numberOfColumns - 1) * gridGapPx;
  const productCardWidth = gridWidthWithoutGaps / numberOfColumns;
  const productIdsToDuplicate = new Set(
    productDuplicatesRef.current.map(
      (productDuplicate) => productDuplicate.productId
    )
  );

  for (let i = 0; i < occupiedByBlocksOrProducts.length; i += numberOfColumns) {
    // Every virtualRow has half a gap as top padding
    topOffset += gridGapPx / 2;

    let leftOffset = dndGridPageX;
    const thisRow = occupiedByBlocksOrProducts.slice(i, i + numberOfColumns);
    for (let j = 0; j < thisRow.length; j++) {
      const item = thisRow[j];
      const itemId =
        typeof item === "number"
          ? productsToRender[item].main_product_id
          : item;

      // Ignore positions of empty slots
      if (itemId != null) {
        const itemIds: string[] = [];

        const productIdOfOriginal =
          getOriginalProductIdForDuplicateProduct(itemId);
        const selectedItemHasOrIsADuplicate =
          productIdsToDuplicate.has(productIdOfOriginal);

        if (selectedItemHasOrIsADuplicate) {
          // When a product duplicate, always treat each part of the duplicate as both of them. That greatly simplifies selection logic.

          const productIdOfDuplicate =
            getProductIdForDuplicateProduct(productIdOfOriginal);
          itemIds.push(productIdOfOriginal, productIdOfDuplicate);
        } else {
          itemIds.push(itemId);
        }

        positions.push({
          itemIds,
          pageLeft: leftOffset,
          pageRight: leftOffset + productCardWidth,
          pageTop: topOffset,
          pageBottom: topOffset + productCardHeight,
        });
      }

      leftOffset += productCardWidth;
      if (j !== thisRow.length - 1) {
        leftOffset += gridGapPx;
      }
    }

    topOffset += productCardHeight;

    // Every virtualRow has half a gap as bottom padding
    topOffset += gridGapPx / 2;
  }

  return positions;
}
