import { useInfiniteQuery } from "@tanstack/react-query";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import { QueryId, getLiteQueryKey } from "src/lite/queries";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { toTzAgnosticIsoDate } from "../../helpers/datetime";
import { CollectionDataOrderBy, CollectionOrderBy } from "../../../api/types";
import { components, operations } from "src/api/generated/openapi";
import { getData } from "src/api/authorizedApi";

// Input types from UI
interface CollectionsWithDataQueryParams {
  from_date: Date;
  to_date: Date;
  page_size: number;
  search_query: string | undefined;
  order_by: CollectionDataOrderBy;
}

interface CollectionsQueryParams {
  page_size: number;
  search_query: string | undefined;
  order_by: CollectionOrderBy;
}

export function useSortedCollections(
  p: CollectionsQueryParams,
  queryOpts?: { enabled?: boolean }
) {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchantId();

  const queryKey = getLiteQueryKey(QueryId.GetSortedCollection, {
    merchantId,
    order_by: p.order_by,
    page_size: p.page_size,
    search_query: p.search_query,
  });

  return useInfiniteQuery({
    queryKey,
    queryFn: async (context: { pageParam: undefined | number }) => {
      const { params, body } = collectionsQueryParams(
        merchantId,
        context.pageParam,
        p
      );
      const response = await api.POST(`/collections/sorted-by`, {
        params,
        body,
      });
      return getData(response);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.has_next) {
        return lastPage.offset;
      }
    },
    staleTime: 1000 * 60,
    ...queryOpts,
  });
}

export function useSortedCollectionsWithData(
  p: CollectionsWithDataQueryParams,
  queryOpts?: { enabled?: boolean }
) {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchantId();

  const queryKey = getLiteQueryKey(QueryId.GetSortedCollectionData, {
    merchantId,
    order_by: p.order_by,
    page_size: p.page_size,
    search_query: p.search_query,
    from_date: toTzAgnosticIsoDate(p.from_date),
    to_date: toTzAgnosticIsoDate(p.to_date),
  });

  return useInfiniteQuery({
    queryKey,
    queryFn: async (context: { pageParam: undefined | number }) => {
      const { params, body } = collectionsWithDataQueryParams(
        merchantId,
        context.pageParam,
        p
      );
      const response = await api.POST(`/collections/data-sorted-by`, {
        params,
        body,
      });
      return getData(response);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.has_next) {
        return lastPage.offset;
      }
    },
    staleTime: 1000 * 60,
    ...queryOpts,
  });
}

export function collectionsQueryParams(
  merchant_id: string,
  pageParam: number | undefined,
  { page_size, order_by, search_query }: CollectionsQueryParams
): {
  params: operations["get_sorted_by_collections_collections_sorted_by_post"]["parameters"];
  body: components["schemas"]["GetSortedPaginatedCollectionsRequest"];
} {
  return {
    params: {
      query: {
        merchant_id,
      },
    },
    body: {
      offset: pageParam ?? 0,
      order_by: order_by,
      page_size,
      query: search_query,
    },
  };
}

export function collectionsWithDataQueryParams(
  merchant_id: string,
  pageParam: number | undefined,
  {
    from_date,
    to_date,
    order_by,
    page_size,
    search_query,
  }: CollectionsWithDataQueryParams
): {
  params: operations["get_data_sorted_collections_collections_data_sorted_by_post"]["parameters"];
  body: components["schemas"]["GetDataSortedPaginatedCollectionsRequest"];
} {
  return {
    body: {
      offset: pageParam ?? 0,
      order_by: order_by,
      page_size,
      query: search_query,
      from_date: toTzAgnosticIsoDate(from_date),
      to_date: toTzAgnosticIsoDate(to_date),
    },
    params: {
      query: {
        merchant_id,
      },
    },
  };
}

export function isCollectionOrderBy(
  order_by: string
): order_by is CollectionOrderBy {
  return ["updated_at", "name_a_z"].includes(order_by);
}

export function isCollectionDataOrderBy(
  order_by: string
): order_by is CollectionDataOrderBy {
  return ["top_views", "top_ctr", "top_clicks"].includes(order_by);
}
