import { positionBlocks } from "../ContentBlock/positionBlocks";
import { GridStateWhileDragging, OnDrop } from "./DragAndDrop/types";
import {
  LiteCollectionDto,
  LiteCollectionProductDto,
} from "../../../api/types";
import { ExtendedProContentBlockWithId } from "../ContentBlock/types";
import React, { Dispatch, SetStateAction } from "react";
import {
  getOriginalProductIdForDuplicateProduct,
  getProductIdForDuplicateProduct,
} from "./ProductHighlight/productDuplicateHelpers";
import { useContentBlocks } from "../ContentBlock/useContentBlocks";
import { getIsDraggingAnyProduct } from "./DragAndDrop/useIsDraggingAnyProduct";
import { getSortByGridOrderPrioritiseBlocks } from "./DragAndDrop/useSortByGridOrder";

/**
 * Creates the onDrop function that moves the products and blocks that were being dragged in drag and drop
 */
export function useCollectionOnDropFactory({
  collection,
  productsToRender: preDragProductsToRender,
  occupiedByBlocksOrProducts: preDragOccupiedByBlocksOrProducts,
  numberOfColumns,
  rowMode,
  localContentBlockState,
  isDragging,
  gridStateWhileDragging,
  ...overridableIncomingProps
}: {
  collection: LiteCollectionDto | null;
  productsToRender: LiteCollectionProductDto[];
  occupiedByBlocksOrProducts: ReturnType<
    typeof useContentBlocks
  >["occupiedByBlocksOrProducts"];
  localContentBlockState: ExtendedProContentBlockWithId[];
  setLocalContentBlockState: (
    value: React.SetStateAction<ExtendedProContentBlockWithId[]>
  ) => void;
  setCollection: Dispatch<SetStateAction<LiteCollectionDto>>;
  numberOfColumns: number;
  rowMode: boolean;
  isDragging: Set<string>;
  gridStateWhileDragging: GridStateWhileDragging | undefined;
}) {
  const isDraggingAnyProduct = getIsDraggingAnyProduct({
    isDragging,
    productsToRender: preDragProductsToRender,
  });

  const onDrop: OnDrop = (movedItems, droppedAt, speculative) => {
    const productsToRender =
      gridStateWhileDragging?.productsToRender || preDragProductsToRender;
    const occupiedByBlocksOrProducts =
      gridStateWhileDragging?.occupiedByBlocksOrProducts ||
      preDragOccupiedByBlocksOrProducts;
    let { setLocalContentBlockState, setCollection } = overridableIncomingProps;
    if (speculative) {
      setLocalContentBlockState = (newValue) =>
        speculative.setLocalContentBlockState(
          typeof newValue === "function"
            ? newValue(localContentBlockState)
            : newValue
        );
      setCollection = (newValue) =>
        speculative.setCollection(
          typeof newValue === "function" ? newValue(collection!) : newValue
        );
    }
    const allProductIdsInBackendOrder = productsToRender.map(
      (product) => product.main_product_id
    );
    const allProductIdsInBackendOrderSet = new Set(allProductIdsInBackendOrder);
    const oldPinnedMainProductIds = [
      ...new Set(
        // Sometimes not both products of a duplicate pair are pinned which causes this logic to miss, so here we're ensuring if any part is pinned, both parts count as pinned
        collection?.pinned_main_product_ids?.flatMap((id) => {
          const toReturn: string[] = [];
          const original = getOriginalProductIdForDuplicateProduct(id);
          const duplicate = getProductIdForDuplicateProduct(id);
          if (allProductIdsInBackendOrderSet.has(original)) {
            toReturn.push(original);
          }
          if (allProductIdsInBackendOrderSet.has(duplicate)) {
            toReturn.push(duplicate);
          }
          return toReturn;
        })
      ),
    ];

    const wentLeft = droppedAt.position === "left";
    // Clone array and prevent duplicate items for when we've added in our new items. Doing it early makes indexInProductsToDropAt more likely to be correct
    const filteredAndClonedPins = oldPinnedMainProductIds.filter(
      (item) => !movedItems.has(item)
    );

    const productIdsInOrder = [
      ...new Set([
        // First add pinned - the correct order of them is only saved here
        ...oldPinnedMainProductIds, // Then add everything, in order of backend
        ...allProductIdsInBackendOrder,
      ]), // De-duplicate away the pinned ones from currentSearchResults with the set (Set preserves order of insertion, so order will be correct still)
    ];

    // We need all the products when finding the index of a product we dropped on for the case of dropping a content block in the unpinned section
    const filteredProductIds = productIdsInOrder.filter(
      (item) => !movedItems.has(item)
    );
    // Sort moved items by their order in occupiedByBlocksOrProducts (the order in the physical grid instead of order of selection)
    const { allSorted: sortedMovedItems } = getSortByGridOrderPrioritiseBlocks(
      movedItems,
      preDragOccupiedByBlocksOrProducts,
      preDragProductsToRender
    );

    const productsToMove: string[] = [];
    const contentBlocksToMove = new Set<string>();
    const contentBlocksById = Object.fromEntries(
      localContentBlockState.map((result) => [result.DOMElementId, result])
    );

    for (const id of sortedMovedItems) {
      if (id in contentBlocksById) {
        contentBlocksToMove.add(id);
      } else {
        productsToMove.push(id);
      }
    }

    let indexInProductsToDropAt: number;
    if (droppedAt.type === "product") {
      let arrayToFindIndexIn = filteredProductIds;
      if (movedItems.has(droppedAt.id)) {
        // We dropped on a product that was being dragged (this happens when dragging a product to the right of the seam between pinned/unpinned products into the right part of the seam), so we need to adjust the index to drop at
        arrayToFindIndexIn = productIdsInOrder.filter(
          (item) => !movedItems.has(item) || item === droppedAt.id
        );
      }

      indexInProductsToDropAt =
        arrayToFindIndexIn.findIndex((product) => product === droppedAt.id) +
        (wentLeft ? 0 : 1);

      indexInProductsToDropAt = Math.max(indexInProductsToDropAt, 0);
    } else {
      // Filter out the products that are being dragged from our map so the indexes will be correct after the splice on filteredAndClonedPins below
      const filteredOccupiedByBlocksOrProducts =
        occupiedByBlocksOrProducts.filter((item) => {
          if (typeof item === "string" || item === null) return true;
          const productId = productIdsInOrder[item];
          return !movedItems.has(productId);
        });

      // Find where the block is in the "map" of actual positions of things in the grid
      let indexInPositions = filteredOccupiedByBlocksOrProducts.findIndex(
        (maybeBlockId) => maybeBlockId === droppedAt.id
      );
      for (
        ;
        // While we're not below index 0
        indexInPositions >= 0 &&
        // Or above the length of the array
        indexInPositions < filteredOccupiedByBlocksOrProducts.length &&
        // And there's a content block at this position
        typeof filteredOccupiedByBlocksOrProducts[indexInPositions] !==
          "number";
        // Decrease/increase the index to find the next product after which we can place our products
        indexInPositions += wentLeft ? -1 : 1
      );
      // Now we have the index in occupiedByBlocksOrProducts of the product "physically" to the left of the block
      // Drop the product at the index of that product in the products array
      const target = filteredOccupiedByBlocksOrProducts[indexInPositions];
      if (typeof target === "number") {
        indexInProductsToDropAt =
          target - (wentLeft ? productsToMove.length - 1 : 0);
      } else {
        // Handle the case that there were content blocks all the way, and there is a content block at position 0 - just drop the product to the start in that case
        indexInProductsToDropAt = 0;
      }
    }

    filteredAndClonedPins.splice(indexInProductsToDropAt, 0, ...productsToMove);

    setLocalContentBlockState((incomingPrev) => {
      // Move content blocks to the point where the user visually dropped
      const prev = speculative?.ignoreBlocks
        ? incomingPrev.filter(
            (block) => !speculative.ignoreBlocks.has(block.DOMElementId)
          )
        : incomingPrev;
      const currentElementAtGridIndex =
        droppedAt.type === "content"
          ? droppedAt.id
          : productsToRender.findIndex(
              (product) => product.main_product_id === droppedAt.id
            );
      const droppedAtPhysicalGridIndex = (
        gridStateWhileDragging?.occupiedByBlocksOrProducts ||
        occupiedByBlocksOrProducts
      ).indexOf(currentElementAtGridIndex);

      const row = Math.floor(droppedAtPhysicalGridIndex / numberOfColumns);

      let newIndex;
      if (rowMode) {
        if (droppedAt.type === "last-row") {
          // Last row virtual drop zone. Put the blocks at the very end
          newIndex = occupiedByBlocksOrProducts.length - 1;
        } else {
          newIndex = Math.max(0, row * numberOfColumns);
        }
      } else {
        newIndex = droppedAtPhysicalGridIndex + (wentLeft ? 0 : 1);
      }

      let newState: ExtendedProContentBlockWithId[];

      const notMovedBlocks = prev.filter(
        (block) => !contentBlocksToMove.has(block.DOMElementId)
      );
      const movedBlocks = prev
        .filter((block) => contentBlocksToMove.has(block.DOMElementId))
        // In the blocks being moved, prioritize in the same order as they were before
        .sort((a, b) => a.index - b.index)
        .map((block) => ({
          ...block,
          // Put all blocks being moved at the same index first
          index: newIndex,
        }));
      if (isDraggingAnyProduct) {
        // Start with existing blocks to give them priority (lock them), when any product is being dragged
        newState = notMovedBlocks;

        // Add back the not moved blocks
        newState.push(...movedBlocks);
      } else {
        // Put the blocks that we moved first in the array, this will make the displacement logic prioritise their position over ones in the way
        newState = movedBlocks;

        // Add back the not moved blocks
        newState.push(...notMovedBlocks);
      }

      // Run the positioning that will displace blocks that don't fit
      const { repositionedBlocks: whereBlocksWouldLand } = positionBlocks(
        newState,
        numberOfColumns,
        productsToRender.length
      );

      // Update the products to actually be at that index and return the new state, to avoid unexpected behavior of blocks not being where they are displayed
      return whereBlocksWouldLand.map(({ block, finalBlockIndex }) => {
        return {
          ...block,
          index: finalBlockIndex,
          scrollIntoViewNextRender:
            contentBlocksToMove.has(block.DOMElementId) &&
            finalBlockIndex !== newIndex
              ? true
              : undefined,
        };
      });
    });

    setCollection((prev) => ({
      ...prev,
      pinned_main_product_ids: filteredAndClonedPins,
    }));
  };

  return onDrop;
}
