export const LEGACY_MERCHANTS = [
  "adaysmarch",
  "aimn",
  "artilleriet",
  "bagarenochkocken",
  "blugiallo",
  "brochuwalker",
  "burgerstore",
  "catjam",
  "confidentliving",
  "cavour",
  "demoripndip",
  "demotest",
  "elon",
  "estore",
  "gabucci",
  "geins",
  "ginatricot",
  "hna",
  "horsestuff",
  "horsestuff2",
  "horsestuff3",
  "idealofsweden",
  "johaug_v2",
  "johaug_v2_qa",
  "karitraa",
  "karitraa_v2",
  "karitraa_v2_qa",
  "kitchenlab",
  "kitchentime",
  "kondomvaruhuset",
  "krauta",
  "lisayang",
  "merchant_id",
  "minirodini",
  "nathalieschuterman",
  "newport",
  "newportv2",
  "nielstest",
  "nordicagolf",
  "nordicspectra",
  "nordiskagalleriet",
  "nudeofscandinavia",
  "nudient",
  "odla",
  "officedepot",
  "plantagen",
  "rains",
  "roseborn",
  "royaldesign",
  "rum21",
  "skistar",
  "spendrups",
  "staples.intershop",
  "staples_intershop",
  "svenskttenn",
  "synsam",
  "temp",
  "twisttango",
  "wakakuu",
  "yaga",
  "zoo",
];
