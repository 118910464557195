import { useMutation } from "@tanstack/react-query";
import { throwErrorIfAny } from "src/api/authorizedApi";
import { LiteCollectionListDto } from "src/api/types";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import { QueryId, getLiteQueryKey } from "src/lite/queries";
import queryClient from "src/queries/client";
import * as Sentry from "@sentry/react";

const getQueryKey = (merchantId: string) => {
  return getLiteQueryKey(QueryId.GetCollections, {
    merchantId: merchantId,
  });
};

interface activateCollectionMutationArgs {
  collectionIds: string[];
  merchantId: string;
  active: boolean;
}

export const useActivateCollection = () => {
  const { api } = useAuthorizedApi();

  const mutation = useMutation({
    mutationFn: async (args: activateCollectionMutationArgs) => {
      const { collectionIds, active, merchantId } = args;

      if (!api) {
        throw new Error("API not available");
      }

      const response = await api.PUT("/collections/activation", {
        params: {
          query: {
            merchant_id: merchantId,
            active,
          },
        },
        body: collectionIds,
      });

      throwErrorIfAny(response);

      return {
        collectionIds,
      };
    },
    onMutate: async (args: activateCollectionMutationArgs) => {
      await queryClient.cancelQueries({
        queryKey: getQueryKey(args.merchantId),
      });

      const previousCollections = queryClient.getQueryData<
        LiteCollectionListDto[]
      >(getQueryKey(args.merchantId));

      if (!previousCollections) {
        return;
      }

      queryClient.setQueryData(
        getQueryKey(args.merchantId),
        previousCollections.map((collection) => {
          if (args.collectionIds.includes(collection.collection_id)) {
            return {
              ...collection,
              sync_back_to_shopify: args.active,
            };
          }

          return collection;
        })
      );

      return {
        collectionId: args.collectionIds,
        previousCollections,
      };
    },
    onError: (error, args, context) => {
      Sentry.captureException(error);
      queryClient.setQueryData(
        getQueryKey(args.merchantId),
        context?.previousCollections
      );
    },
    onSettled: async (data, error, args) => {
      await queryClient.invalidateQueries({
        queryKey: [QueryId.GetSortedCollectionData, args.merchantId],
      });
      await queryClient.invalidateQueries({
        queryKey: getQueryKey(args.merchantId),
      });
      await queryClient.invalidateQueries({
        queryKey: getLiteQueryKey(QueryId.GetNbrOfActiveCollections, {
          merchantId: args.merchantId,
        }),
      });
      await queryClient.invalidateQueries({
        queryKey: getLiteQueryKey(QueryId.GetSubscriptionStatus, {
          merchantId: args.merchantId,
        }),
      });
      args.collectionIds.forEach((collectionId) => {
        queryClient.invalidateQueries({
          queryKey: getLiteQueryKey(QueryId.GetCollectionVersions, {
            merchantId: args.merchantId,
            collectionId: collectionId,
          }),
        });
      });
      args.collectionIds.forEach((collectionId) => {
        queryClient.invalidateQueries({
          queryKey: getLiteQueryKey(QueryId.GetCollection, {
            merchantId: args.merchantId,
            collectionId,
          }),
        });
      });
      args.collectionIds.forEach((collectionId) => {
        queryClient.refetchQueries({
          queryKey: getLiteQueryKey(QueryId.GetCollection, {
            merchantId: args.merchantId,
            collectionId,
          }),
        });
      });
    },
  });

  return mutation;
};

export default useActivateCollection;
