import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import React from "react";
import { css, Interpolation, Theme, useTheme } from "@emotion/react";
import { Icon } from "@phosphor-icons/react";

const SwitchToggleRoot = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>((props, ref) => {
  return (
    <RadioGroupPrimitive.Root
      css={(theme) => css`
        width: 100%;
        display: flex;
        padding: 4px;
        gap: 4px;
        align-items: center;
        justify-content: space-between;
        background: ${theme.colors.bg["weak-50"]};
        border-radius: 6px;
      `}
      ref={ref}
      {...props}
    />
  );
});

const SwitchToggleItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    text?: string | React.ReactNode; // Not called title due to collision with HTML attribute
    LeftIcon?: Icon;
    hoverTitle?: string;
    children?: React.ReactNode;
    extraCss?: Interpolation<Theme>;
  }
>(
  (
    { text, LeftIcon, hoverTitle, color, children, extraCss, ...props },
    ref
  ) => {
    const theme = useTheme();

    return (
      <RadioGroupPrimitive.Item
        ref={ref}
        {...props}
        css={[
          theme.typography["label-s"],
          css`
            flex: 1;
            border-radius: 6px;
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            color: ${color ? color : theme.colors.text["soft-400"]};
            border: none;
            background: none;

            transition:
              background 0.2s ease-in-out,
              color 0.2s ease-in-out;

            &:hover {
              background: ${theme.colors.bg["weak-50"]};
              color: ${color || theme.colors.text["sub-600"]};
              cursor: pointer;
            }

            &[data-state="checked"] {
              color: ${color || theme.colors.text["strong-950"]};
              background: ${theme.colors.bg["white-0"]};
              box-shadow:
                0 6px 10px 0 rgba(14, 18, 27, 0.06),
                0 2px 4px 0 rgba(14, 18, 27, 0.03);
            }
          `,
          extraCss,
        ]}
        {...(hoverTitle && { title: hoverTitle })}
      >
        {LeftIcon && <LeftIcon size={20} color={color} />}
        {text && <span>{text}</span>}
        {children}
      </RadioGroupPrimitive.Item>
    );
  }
);
SwitchToggleItem.displayName = RadioGroupPrimitive.Item.displayName;

export { SwitchToggleRoot, SwitchToggleItem };
