import { css } from "@emotion/react";
import { MagnifyingGlass, SquaresFour } from "@phosphor-icons/react";
import { Suspense, memo, useCallback, useMemo, useState } from "react";
import { CollectionTable } from "./CollectionTable/CollectionTable";
import { Alert } from "src/alignUI/Alert/Alert";
import { DepictLink } from "../../../alignUI/DepictLink/DepictLink";
import { EmptyState } from "../../../alignUI/EmptyState/EmptyState";
import Container from "src/alignUI/Container/Container";
import {
  isCollectionDataOrderBy,
  isCollectionOrderBy,
  useSortedCollections,
  useSortedCollectionsWithData,
} from "./useCollectionWithData";
import { CollectionDataOrderBy, CollectionOrderBy } from "../../../api/types";
import { CollectionsHeader } from "./CollectionsHeader/CollectionsHeader";
import { useDebounce, useLocalStorage } from "usehooks-ts";
import { useMutation } from "@tanstack/react-query";
import useActivateCollection from "./useActivateCollection";
import { useAlert } from "../../../alignUI/Alert/useAlert/useAlert";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { getFromPeriod, Period } from "../Dashboard/util";
import { useInstantColumnSettings } from "../Settings/ColumnSettings/useInstantColumnSettings";
import * as Sentry from "@sentry/react";
import useMerchant from "../../../helpers/hooks/app/useMerchant";
import { usePercentageBasedInfiniteScroll } from "../../../alignUI/FileUploadArea/usePercentageBasedInfiniteScroll";
import { TrialModal } from "../Settings/PlanSettings/Modals/TrialModal";
import usePlanStatus from "../../helpers/usePlanStatus";
import { useSubscriptionStatus } from "../Settings/PlanSettings/useSubscriptionStatus";

export const paginationPageSize = 24;

const Collections = () => {
  const { merchantId } = useMerchantId();
  const { addAlert } = useAlert();

  const now = useMemo(() => new Date(), []);

  const { merchant } = useMerchant();
  const minTimestamp = merchant?.start_timestamp
    ? new Date(merchant.start_timestamp * 1000)
    : new Date();

  const defaultPeriodType =
    minTimestamp > getFromPeriod("4w", now) ? "24h" : "4w";

  const [period, setPeriod] = useState<Period>({
    type: defaultPeriodType,
    fromDate: getFromPeriod(defaultPeriodType, now),
    toDate: now,
  });

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const [orderBy, setOrderBy] = useState<
    CollectionOrderBy | CollectionDataOrderBy
  >("updated_at");

  const sortedCollectionsQuery = useSortedCollections(
    {
      page_size: paginationPageSize,
      search_query: debouncedSearchQuery,
      order_by: isCollectionOrderBy(orderBy) ? orderBy : "updated_at",
    },
    {
      enabled: isCollectionOrderBy(orderBy),
    }
  );
  const sortedCollectionsWithDataQuery = useSortedCollectionsWithData(
    {
      page_size: paginationPageSize,
      search_query: debouncedSearchQuery,
      order_by: isCollectionDataOrderBy(orderBy) ? orderBy : "top_clicks",
      from_date: period.fromDate,
      to_date: period.toDate,
    },
    {
      enabled: isCollectionDataOrderBy(orderBy),
    }
  );

  const collectionsQuery = isCollectionDataOrderBy(orderBy)
    ? sortedCollectionsWithDataQuery
    : sortedCollectionsQuery;


  const data = useMemo(() => {
    return (
      collectionsQuery.data?.pages.flatMap((page) => page.collections) || []
    );
  }, [collectionsQuery.data]);

  const { makeInfiniteRef: loadMoreRef } = usePercentageBasedInfiniteScroll(
    collectionsQuery,
    data.length
  );

  const hasNoCollections =
    collectionsQuery.data?.pages.length === 0 && !collectionsQuery.isLoading;

  const hasNoSearchResults =
    data.length === 0 &&
    debouncedSearchQuery.length > 0 &&
    !collectionsQuery.isLoading &&
    !hasNoCollections;

  const NoRowsOverlay = useMemo(() => {
    if (hasNoSearchResults) {
      return <EmptyState message={"No results found"} icon={MagnifyingGlass} />;
    }

    return (
      <EmptyState
        message={"You don’t have any collections"}
        icon={SquaresFour}
      />
    );
  }, [hasNoSearchResults]);

  const activateCollectionMutation = useActivateCollection();
  const activateCollection = activateCollectionMutation.mutateAsync;

  const { mutate: setDepictUnPublishState } = useMutation({
    mutationFn: async ({ collectionIds }: { collectionIds: string[] }) => {
      try {
        await activateCollection({
          collectionIds,
          merchantId,
          active: false,
        });
      } catch (error) {
        Sentry.captureException(error);
        addAlert({
          text: "Oops! ",
          description:
            "For some reason, we couldn’t un-publish this collection. Try again later, or contact support.",
          status: "error",
          styling: "filled",
          size: "large",
          autoClose: 5000,
        });
        return;
      }

      const text = "Un-published collection";

      addAlert({
        text,
        status: "success",
        styling: "stroke",
        size: "small",
      });
    },
    // Todo refetch collection on un publish make sure the collection is un-published
  });

  const onUnPublishDepict = useCallback(
    async (collectionId: string) => {
      setDepictUnPublishState({
        collectionIds: [collectionId],
      });
    },
    [setDepictUnPublishState]
  );
  // Hack: load column settings to prevent flashing when opening a collection
  useInstantColumnSettings();

  return (
    <Container
      customCss={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 16px 0;
      `}
    >
      <CollectionsHeader />
      {collectionsQuery.isError ? (
        <div
          css={css`
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <Alert
            status={"error"}
            styling={"stroke"}
            size={"large"}
            text={"Oops!"}
            description="For some reason, we couldn’t load the page. Try to reload the page. If the problem persists please contact support."
            component={
              <DepictLink
                onClick={() => collectionsQuery.refetch()}
                underline={true}
              >
                Refresh
              </DepictLink>
            }
          />
        </div>
      ) : (
        <>
          <MemoizedCollectionTable
            loadMoreRef={loadMoreRef}
            onSearch={setSearchQuery}
            collections={data}
            isLoading={collectionsQuery.isLoading}
            noRowsOverlayComponent={NoRowsOverlay}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            onUnPublishDepict={onUnPublishDepict}
            period={period}
            setPeriod={setPeriod}
          />
        </>
      )}
      <Suspense>
        <ShowTrialModal />
      </Suspense>
    </Container>
  );
};

function ShowTrialModal() {
  const planStatus = usePlanStatus();
  const subscriptionStatus = useSubscriptionStatus();

  const [hasSeenExpiredModal, setHasSeenExpiredModal] =
    useLocalStorage<boolean>("depictHasSeenExpiredModalV1", false);
  const trialDaysLeft = planStatus.trial_days_left ?? 0;

  const [openTrialModal, setOpenTrialModal] = useState(
    planStatus.installed_recently &&
      !subscriptionStatus.isPaid &&
      ((planStatus.is_trial && trialDaysLeft <= 2 && !hasSeenExpiredModal) ||
        (!planStatus.is_trial && !hasSeenExpiredModal))
  );

  return (
    <TrialModal
      open={openTrialModal}
      onClose={() => {
        setOpenTrialModal(!openTrialModal);
        setHasSeenExpiredModal(true);
      }}
    />
  );
}

const MemoizedCollectionTable = memo(CollectionTable);

export default Collections;

export const Component = Collections;
