import { useDragAndDropContext } from "./DragAndDropProvider";
import { LiteCollectionProductDto } from "../../../../api/types";

export function useIsDraggingAnyProduct() {
  const { isDragging, productsToRender } = useDragAndDropContext();
  return getIsDraggingAnyProduct({ isDragging, productsToRender });
}

export function getIsDraggingAnyProduct({
  productsToRender,
  isDragging,
}: {
  productsToRender: LiteCollectionProductDto[];
  isDragging: Set<string>;
}) {
  // is any of the items in isDragging a product
  const isDraggingAnyProduct = Array.from(isDragging).some((item) => {
    return productsToRender.some((product) => product.main_product_id === item);
  });
  return isDraggingAnyProduct;
}
