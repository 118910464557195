export default function Logo({
  color,
  size = 20,
}: {
  color?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color || "currentColor"}
    >
      <g clipPath="url(#clip0_818_55554)">
        <path
          d="M9 12.75H2.25C1.00736 12.75 0 13.7574 0 15V21.75C0 22.9926 1.00736 24 2.25 24H9C10.2426 24 11.25 22.9926 11.25 21.75V15C11.25 13.7574 10.2426 12.75 9 12.75Z"
          fill="current"
        />
        <path
          d="M21.75 17.25H15C13.7574 17.25 12.75 18.2574 12.75 19.5V21.75C12.75 22.9926 13.7574 24 15 24H21.75C22.9926 24 24 22.9926 24 21.75V19.5C24 18.2574 22.9926 17.25 21.75 17.25Z"
          fill="current"
        />
        <path
          d="M9 0H2.25C1.00736 0 0 1.00736 0 2.25V9C0 10.2426 1.00736 11.25 2.25 11.25H9C10.2426 11.25 11.25 10.2426 11.25 9V2.25C11.25 1.00736 10.2426 0 9 0Z"
          fill="current"
        />
        <path
          d="M21.75 0H15C13.7574 0 12.75 1.00736 12.75 2.25V13.5C12.75 14.7426 13.7574 15.75 15 15.75H21.75C22.9926 15.75 24 14.7426 24 13.5V2.25C24 1.00736 22.9926 0 21.75 0Z"
          fill="current"
        />
      </g>
      <defs>
        <clipPath id="clip0_818_55554">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
