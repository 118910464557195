import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getLiteQueryKey, QueryId } from "../queries";
import { getData } from "../../api/authorizedApi";
import { ShopifyMultiStore } from "../../api/types";
import { useMaybeAppBridge } from "../../helpers/shopify/useMaybeAppBridge";
import { useMemo } from "react";

export const useMultiStore = () => {
  const { merchantId } = useMerchantId();
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery({
    queryKey: getLiteQueryKey(QueryId.GetMultiStoreStatus, {
      merchantId,
    }),
    queryFn: async () => {
      if (!api) return;
      const response = await api.GET("/merchants/{merchant_id}/multi-store", {
        params: {
          path: {
            merchant_id: merchantId,
          },
        },
      });

      return getData(response);
    },
    enabled: !!api,
  });

  const createMultiStoreMutation = useMutation({
    mutationFn: async () => {
      if (!api) return;
      const response = await api.POST(
        "/merchants/{merchant_id}/multi-store/create",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      return getData(response);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: getLiteQueryKey(QueryId.GetMultiStoreStatus, {
          merchantId: merchantId,
        }),
      });
    },
  });

  const deleteMultiStoreMutation = useMutation({
    mutationFn: async (activationCode: string) => {
      if (!api) return;
      const response = await api.DELETE(
        "/merchants/{merchant_id}/multi-store/{activation_code}",
        {
          params: {
            path: {
              merchant_id: merchantId,
              activation_code: activationCode,
            },
          },
          data,
        }
      );

      return getData(response);
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: getLiteQueryKey(QueryId.GetMultiStoreStatus, {
          merchantId: merchantId,
        }),
      });
    },
  });

  return {
    data,
    isLoading,
    byShopId: data?.reduce(
      (acc, store) => {
        if (store.target_shop_id) {
          acc[store.target_shop_id] = store;
        }
        return acc;
      },
      {} as Record<string, ShopifyMultiStore>
    ),
    createMultiStore: createMultiStoreMutation.mutate,
    deleteMultiStore: deleteMultiStoreMutation.mutate,
  };
};

export const useMultiStoreValidation = () => {
  const appBridgeMaybe = useMaybeAppBridge();
  const { api } = useAuthorizedApi();
  const queryClient = useQueryClient();

  const subStoreShopUrl = useMemo(() => {
    return appBridgeMaybe?.config.shop;
  }, [appBridgeMaybe]);

  const subStoreStatusQuery = useQuery({
    queryKey: getLiteQueryKey(QueryId.ShopifyGetSubStoreStatus, {
      subStoreShopUrl,
    }),
    queryFn: async () => {
      if (!api || typeof subStoreShopUrl === "undefined") return;
      const response = await api.GET("/multi-store/is-sub-store");
      return getData(response);
    },
    enabled: !!api && !!subStoreShopUrl,
  });

  const validateMultiStoreActivationCode = useMutation({
    mutationFn: async (activationCode: string) => {
      if (!api) return;
      const response = await api.GET("/multi-store/validate", {
        params: {
          query: {
            activation_code: activationCode,
          },
        },
      });

      return getData(response);
    },
  });

  const activateMultiStore = useMutation({
    mutationFn: async (activationCode: string) => {
      if (!api) return;
      const response = await api.POST("/multi-store/activate", {
        body: {
          activation_code: activationCode,
        },
      });

      if (response.response.status === 200) {
        await queryClient.invalidateQueries({
          queryKey: getLiteQueryKey(QueryId.ShopifyGetSubStoreStatus, {
            subStoreShopUrl,
          }),
        });
        return true;
      }
      return false;
    },
  });

  return {
    isSubStore: subStoreStatusQuery.data?.is_sub_store ?? false,
    merchantDomain: subStoreStatusQuery.data?.merchant_domain,
    merchantId: subStoreStatusQuery.data?.merchant_id,
    isLoadingSubStoreInfo: subStoreStatusQuery.isLoading,
    validateActivationCode: validateMultiStoreActivationCode,
    activateMultiStore: activateMultiStore,
  };
};
