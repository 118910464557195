import { css, useTheme } from "@emotion/react";
import React, { Suspense, useEffect } from "react";
import { Outlet, useNavigation, useParams } from "react-router";
import { useSetupThirdPartiesThatDependOnUser } from "src/helpers/hooks/useSetupThirdPartiesThatDependOnUser";
import useUser from "src/helpers/hooks/useUser";
import { LiteUser } from "src/api/types";
import useMerchant from "../../../helpers/hooks/app/useMerchant";
import MaybeInvalidConfigBanner from "./MaybeInvalidConfigBanner";
import SplashScreen from "src/lite/components/SplashScreen/SplashScreen";
import "@ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import { useMaybeAppBridge } from "src/helpers/shopify/useMaybeAppBridge";
import { ShopifyNavMenu } from "./ShopifyNavMenu";
import useShowIntercom from "../../../helpers/hooks/intercom/useShowIntercom";
import useIsMobile from "../../../helpers/hooks/useIsMobile";
import MaybeFrozenBanner from "./MaybeFrozenBanner";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";

// Lazy-load components only used on lite.depict.ai to reduce bundle size.
const DesktopSidebar = React.lazy(() =>
  import("src/alignUI/Sidebar/Sidebar").then((module) => ({
    default: module.DesktopSidebar,
  }))
);
const MobileSidebar = React.lazy(() =>
  import("src/alignUI/Sidebar/Sidebar").then((module) => ({
    default: module.MobileSidebar,
  }))
);
const Topbar = React.lazy(() =>
  import("src/alignUI/Sidebar/Topbar").then((module) => ({
    default: module.default,
  }))
);

const OutletWrapper = () => {
  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-direction: column;
        width: 100%;
      `}
    >
      <Suspense>
        <MaybeInvalidConfigBanner />
        <MaybeFrozenBanner />
      </Suspense>
      <Suspense fallback={<SplashScreen />}>
        <Outlet />
      </Suspense>
    </div>
  );
};

interface LayoutProps {
  collapsed: boolean | null;
  setCollapsed: (collapsed: boolean) => void;
  outlet: React.ReactNode;
  user?: LiteUser;
}

function useTrackLaunch(merchantId?: string) {
  const { api } = useAuthorizedApi();
  useEffect(() => {
    if (!merchantId) return;
    if (typeof shopify === "undefined") return;
    api.POST("/arm/launch", {
      params: { query: { merchant_id: merchantId } },
    });
  }, []);
}

function ShopifyLoading() {
  const { state } = useNavigation();

  const isFetchingAnyQuery = useIsFetching({
    predicate: (query) => query.state.status === "pending",
  });

  useEffect(() => {
    if (typeof shopify !== "undefined") {
      shopify?.loading(state !== "idle" || isFetchingAnyQuery > 0);
      return () => shopify?.loading(false);
    }
  }, [isFetchingAnyQuery, state]);

  return null;
}

const Layout = React.memo((props: LayoutProps) => {
  const { collapsed, setCollapsed } = props;
  const { merchant } = useMerchant();
  const theme = useTheme();
  const appBridge = useMaybeAppBridge();
  const isMobile = useIsMobile();

  useTrackLaunch(merchant?.id);

  // copied from original mobileLayout, unsure why this is set this way
  useShowIntercom({ show: !isMobile || collapsed === null || collapsed });

  if (!merchant) {
    return null;
  }

  const embedded = appBridge?.environment.embedded;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
      `}
    >
      <ShopifyLoading />
      {!embedded && <Topbar onExpand={() => setCollapsed(false)} />}
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: row;
          width: 100%;
          background: ${theme.colors.bg.shopify};
        `}
      >
        {embedded ? (
          <ShopifyNavMenu merchantId={merchant.id} />
        ) : (
          <DesktopSidebar
            persona={merchant?.display_name || "Merchant"}
            email={props.user?.email || ""}
            merchantId={merchant?.id || ""}
            collapsed={collapsed === null ? false : collapsed}
            setCollapsed={setCollapsed}
          />
        )}
        {props.outlet}
        {!embedded && (
          <MobileSidebar
            persona={merchant?.display_name || "Merchant"}
            email={props.user?.email || ""}
            merchantId={merchant?.id || ""}
            collapsed={collapsed === null ? true : collapsed}
            setCollapsed={setCollapsed}
          />
        )}
      </div>
    </div>
  );
});

const DepictLiteGlobalLayout = () => {
  const [collapsed, setCollapsed] = React.useState<null | boolean>(null);
  const { user } = useUser();
  const { merchantId } = useParams();

  useSetupThirdPartiesThatDependOnUser(user);

  // Send merchantId to Jimo
  useEffect(() => {
    if (!merchantId || !user || user.is_superuser) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)?.jimo?.push?.(["do", "identify", [merchantId]]);
  }, [merchantId, user, user?.is_superuser]);

  return (
    <Layout
      collapsed={collapsed}
      setCollapsed={setCollapsed}
      outlet={<OutletWrapper />}
      user={user}
    />
  );
};

export default DepictLiteGlobalLayout;
