import React, { createContext, type JSX, useContext, useMemo } from "react";
import { GraphQLClient } from "graphql-request";
import { getAccessToken } from "./app/useAuthentication";
import config from "../../config";
import { useOptionalMerchantId } from "./app/useMerchantId";

const AdminAPIClientContext = createContext<
  undefined | { client?: GraphQLClient }
>(undefined);

export function AdminAPIClientProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { merchantId } = useOptionalMerchantId();
  const client = useMemo(() => {
    if (!merchantId) return;
    return new GraphQLClient(
      new URL(
        `/api/lite/shopify/proxy/${merchantId}/admin-api/graphql.json`,
        // I got the case where config.apiClient.baseUrl was relative, so support it both being relative and absolute. We only want to know from it if the domain is different, which it is when embedded.
        new URL(config.apiClient.baseUrl || "/", location.href)
      ).toString(),
      {
        requestMiddleware: async (request) => {
          // When running embedded inside shopify the access token changes every minute, so get it in each request
          const headers = new Headers(request.headers);
          const accessToken = await getAccessToken();
          headers.set(`Authorization`, "Bearer " + accessToken);

          return {
            ...request,
            headers,
          };
        },
      }
    );
  }, [merchantId]);

  return (
    <AdminAPIClientContext.Provider value={{ client }}>
      {children}
    </AdminAPIClientContext.Provider>
  );
}

export function useAdminAPIClient() {
  return useContext(AdminAPIClientContext)?.client;
}
