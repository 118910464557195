import { css } from "@emotion/react";
import DepictLogo from "../../../Logo";
import { Wordmark } from "./Wordmark";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";

export const Logo = () => {
  const isMobile = useIsMobile();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: center;
      `}
    >
      <DepictLogo size={isMobile ? 35 / 2 : 35} />
      <Wordmark
        {...(isMobile && {
          width: 140 / 2,
          height: 44 / 2,
        })}
      />
    </div>
  );
};
