import { css } from "@emotion/react";
import { Badge } from "../../alignUI/Badges/Badge";
import { Button } from "../../alignUI/Button/Button";
import { DepictLink } from "../../alignUI/DepictLink/DepictLink";

export default function SubStoreScreen({
  merchantId,
  domain,
}: {
  merchantId: string;
  domain: string;
}) {
  const mainStoreURL = `https://admin.shopify.com/store/${domain?.split(".myshopify.com")[0]}`;
  return (
    <div
      css={(theme) => css`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        background-color: ${theme.colors.bg["white-0"]};
      `}
    >
      <div
        css={css`
          display: flex;
          margin: 32px 0;
          flex-direction: column;
          row-gap: 20px;
          max-width: 480px;
        `}
      >
        <h1 css={(theme) => theme.typography.h5}>
          <div
            css={css`
              margin-bottom: 8px;
            `}
          >
            <Badge _style="lighter" color="blue" text={"SUB-STORE"} />
          </div>
          This is not your main store.
        </h1>

        <p>
          To avoid conflicts, we are redirecting you to your main store.
          <br />
          If you wish to use the Depict app with this sub-store, you can un-link
          it from the settings.
        </p>

        <div
          css={css`
            display: flex;
            gap: 12px;
          `}
        >
          <DepictLink
            href={`${mainStoreURL}/apps/depict/${merchantId}/settings`}
            target={"_blank"}
            css={css`
              &:hover {
                text-decoration: none;
              }
            `}
          >
            <Button _style="stroke" variant="neutral">
              Go to main store settings
            </Button>
          </DepictLink>
          <DepictLink
            href={mainStoreURL}
            target={"_blank"}
            css={css`
              &:hover {
                text-decoration: none;
              }
            `}
          >
            <Button _style="filled" variant="primary">
              Go to main store
            </Button>
          </DepictLink>
        </div>
      </div>
    </div>
  );
}
