import { Suspense, lazy, useCallback, useMemo, useState } from "react";
import {
  CollectionDataOrderBy,
  CollectionOrderBy,
  LiteCollectionListDto,
  LiteDashboardCollectionDto,
} from "src/api/types";
import { css, useTheme } from "@emotion/react";
import { Collection } from "./CollectionTable.types";
import { List, SquaresFour } from "@phosphor-icons/react";
import useTopCollectionsData from "../../Dashboard/useTopCollectionsData";
import useGetCollectionsOutOfStockStatus from "../useGetCollectionsOutOfStockStatus";
import { useMultiStore } from "src/lite/helpers/useMultiStore";
import { Search } from "src/alignUI/Search/Search";
import {
  TabMenuHorizontal,
  TabMenuHorizontalItem,
} from "src/alignUI/TabMenuHorizontal/TabMenuHorizontal";
import useInstalledMoreThanADay from "../../../../helpers/hooks/useInstalledMoreThanADay";
import { CollectionBadge } from "src/lite/components/CollectionBadges";
import { CollectionList } from "./CollectionList";
import {
  SwitchToggleItem,
  SwitchToggleRoot,
} from "../../../../alignUI/SwitchToggle/SwitchToggle";
import { CollectionGrid } from "./CollectionGrid";
import { Period } from "../../Dashboard/util";
import { DatePicker } from "./DatePicker";
import { useLocalStorage } from "usehooks-ts";

const ClickthroughRateModal = lazy(
  () => import("../../Dashboard/Modal/ClickthroughRateModal")
);

const TOP_N = 10;

const collectionOrderByValues: {
  name: string;
  key: CollectionOrderBy | CollectionDataOrderBy;
}[] = [
  { name: "Recent", key: "updated_at" },
  { name: "Top Views", key: "top_views" },
  { name: "Top Clicks", key: "top_clicks" },
  { name: "Top CTR", key: "top_ctr" },
  { name: "A-Z", key: "name_a_z" },
];

const fakeDataWhileLoading: Collection[] = Array.from(
  { length: 12 },
  (_, index) => {
    const collection: Collection = {
      coverImages: undefined,
      productImages: undefined,
      title: "",
      numProducts: 0,
      hasDepictConfiguration: true,
      syncBackToShopify: true,
      id: index.toString(),
      badges: [],
      missingInSubStores: [],
      views: null,
      clicks: null,
      clickthrough_rate: null,
    };

    return collection;
  }
);

interface CollectionTableProps {
  collections: LiteDashboardCollectionDto[] | LiteCollectionListDto[];
  isLoading: boolean;
  noRowsOverlayComponent?: JSX.Element;
  onSearch: (search: string) => void;
  setOrderBy: (orderBy: CollectionOrderBy | CollectionDataOrderBy) => void;
  orderBy: CollectionOrderBy | CollectionDataOrderBy;
  loadMoreRef: (index: number) => (element: HTMLElement | null) => void;
  onUnPublishDepict: (collectionId: string) => void;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
}

export const CollectionTable = ({
  collections,
  isLoading,
  onSearch,
  setOrderBy,
  orderBy,
  loadMoreRef,
  noRowsOverlayComponent: noRowsFallback,
  onUnPublishDepict,
  period,
  setPeriod,
}: CollectionTableProps) => {
  const { byShopId: multiStores } = useMultiStore();

  const topCollectionData = useTopCollectionsData({
    from_date: period.fromDate,
    to_date: period.toDate,
  });

  const [topPopularCollections, topConvertingCollections] = useMemo(() => {
    const topPopularCollections = new Map<string, CollectionBadge>();
    const topConvertingCollections = new Map<string, CollectionBadge>();

    topCollectionData.data?.top_clicked_collections.forEach(
      (collection, index) => {
        topPopularCollections.set(collection.collection_id, {
          type: "popular",
          clicks: collection.clicks,
          rank: index + 1,
        });
      }
    );

    topCollectionData.data?.top_ctr_collections.forEach((collection, index) => {
      topConvertingCollections.set(collection.collection_id, {
        type: "conversion_rate",
        conversionRate: collection.clickthrough_rate || 0,
        rank: index + 1,
      });
    });

    return [topPopularCollections, topConvertingCollections];
  }, [topCollectionData.data]);

  const { data: outOfStockStatus } = useGetCollectionsOutOfStockStatus();

  const data = useMemo(() => {
    if (isLoading) {
      return fakeDataWhileLoading;
    }

    return collections.map((collection) => {
      const syncBackToShopify = collection.sync_back_to_shopify;
      const hasDepictConfiguration = collection.has_depict_configuration;

      const badges = [
        topConvertingCollections?.get(collection.collection_id),
        topPopularCollections?.get(collection.collection_id),
      ].filter((badge): badge is CollectionBadge => !!badge);

      const productsOutOfStock = outOfStockStatus?.[collection.collection_id];

      if (productsOutOfStock && productsOutOfStock.length > 0) {
        badges.push({
          type: "out_of_stock",
          mainProductIds: productsOutOfStock,
        });
      }

      const missingShopIds = Object.keys(multiStores ?? {}).filter(
        (shopId) => !collection.connected_shop_ids?.includes(shopId)
      );

      if (syncBackToShopify) {
        badges.unshift({ type: "live" });
      } else if (hasDepictConfiguration) {
        badges.unshift({ type: "draft" });
      }

      const _collection: Collection = {
        id: collection.collection_id,
        coverImages: collection.image_urls,
        productImages: collection.product_images,
        title: collection.title,
        syncBackToShopify,
        hasDepictConfiguration,

        numProducts: collection.n_products,
        badges,
        missingInSubStores:
          missingShopIds && multiStores
            ? (missingShopIds
                .map((shopId) => multiStores[shopId]?.shopify_base_url)
                .filter((d) => d) as string[])
            : [],
        views:
          "selected_period_data" in collection
            ? collection.selected_period_data.views
            : null,
        clicks:
          "selected_period_data" in collection
            ? collection.selected_period_data.clicks
            : null,
        clickthrough_rate:
          "selected_period_data" in collection
            ? collection.selected_period_data.clickthrough_rate
            : null,
      };

      return _collection;
    });
  }, [
    collections,
    isLoading,
    multiStores,
    outOfStockStatus,
    topConvertingCollections,
    topPopularCollections,
  ]);

  const [selectedDashboardCollectionId, setSelectedDashboardCollectionId] =
    useState<string | undefined>(undefined);

  const onClose = useCallback(
    () => void setSelectedDashboardCollectionId(undefined),
    [setSelectedDashboardCollectionId]
  );

  const installedMoreThanADay = useInstalledMoreThanADay();

  return (
    <>
      <TableContent
        onSearch={onSearch}
        collections={data}
        setOrderBy={setOrderBy}
        orderBy={orderBy}
        isLoading={isLoading}
        loadMoreRef={loadMoreRef}
        setSelectedCollectionId={setSelectedDashboardCollectionId}
        onUnPublishDepict={onUnPublishDepict}
        noRowsFallback={noRowsFallback}
        collectingData={!installedMoreThanADay}
        period={period}
        setPeriod={setPeriod}
      />
      <Suspense>
        {selectedDashboardCollectionId && (
          <ClickthroughRateModal
            onSetSelectedCollectionId={setSelectedDashboardCollectionId}
            selectedCollectionId={selectedDashboardCollectionId}
            open={selectedDashboardCollectionId !== undefined}
            onClose={onClose}
            defaultPeriod={period}
          />
        )}
      </Suspense>
    </>
  );
};

function TableContent({
  collections,
  onSearch,
  setOrderBy,
  orderBy,
  isLoading,
  loadMoreRef,
  onUnPublishDepict,
  noRowsFallback: noRowsFallback,
  setSelectedCollectionId,
  collectingData,
  period,
  setPeriod,
}: {
  collections: Collection[];
  onSearch: (search: string) => void;
  setOrderBy: (orderBy: CollectionOrderBy | CollectionDataOrderBy) => void;
  orderBy: CollectionOrderBy | CollectionDataOrderBy;
  isLoading: boolean;
  loadMoreRef: (index: number) => (element: HTMLElement | null) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  noRowsFallback?: JSX.Element;
  collectingData: boolean;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
}) {
  const theme = useTheme();

  const [view, setView] = useLocalStorage<"grid" | "list">(
    "depict_showCollectionsGridList_v1",
    "grid"
  );

  return (
    <div
      css={css`
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        flex: 1;

        border-radius: 16px;
        border: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["white-0"]};
      `}
    >
      <div
        css={[
          (theme) => css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            width: 100%;
            border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};

            @media (max-width: ${theme.breakpoints.md}px) {
              flex-direction: column-reverse;
              gap: 4px;
              align-items: start;
            }
          `,
        ]}
      >
        <OrderByTabs orderBy={orderBy} setOrderBy={setOrderBy} />
        <div
          css={css`
            display: flex;
            gap: 8px;
            @media (max-width: ${theme.breakpoints.md}px) {
              justify-content: space-between;
              width: 100%;
            }
          `}
        >
          <Search
            openWidth={120}
            onSearch={onSearch}
            extraCss={css`
              min-width: unset;
            `}
          />
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <DatePicker period={period} setPeriod={setPeriod} />
            <div
              css={css`
                flex: 0;
              `}
            >
              <SwitchToggleRoot
                value={view}
                onValueChange={(value) => {
                  setView(value as "grid" | "list");
                }}
              >
                <SwitchToggleItem LeftIcon={SquaresFour} value={"grid"} />
                <SwitchToggleItem LeftIcon={List} value={"list"} />
              </SwitchToggleRoot>
            </div>
          </div>
        </div>
      </div>

      <>
        {collections.length === 0 ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              padding: 20px;
            `}
          >
            {noRowsFallback}
          </div>
        ) : (
          <>
            {view === "grid" ? (
              <CollectionGrid
                collections={collections}
                isLoading={isLoading}
                loadMoreRef={loadMoreRef}
                onUnPublishDepict={onUnPublishDepict}
                setSelectedCollectionId={setSelectedCollectionId}
              />
            ) : (
              <CollectionList
                collections={collections}
                orderBy={orderBy}
                isLoading={isLoading}
                loadMoreRef={loadMoreRef}
                onUnPublishDepict={onUnPublishDepict}
                setSelectedCollectionId={setSelectedCollectionId}
                collectingData={collectingData}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}

const OrderByTabs = ({
  orderBy,
  setOrderBy,
}: {
  orderBy: CollectionOrderBy | CollectionDataOrderBy;
  setOrderBy: (orderBy: CollectionOrderBy | CollectionDataOrderBy) => void;
}) => {
  return (
    <TabMenuHorizontal
      value={orderBy}
      onValueChange={(value) => {
        setOrderBy(value as CollectionOrderBy);
      }}
    >
      {collectionOrderByValues.map((collectionOrderByValue) => (
        <TabMenuHorizontalItem
          key={collectionOrderByValue.name}
          value={collectionOrderByValue.key}
        >
          {collectionOrderByValue.name}
        </TabMenuHorizontalItem>
      ))}
    </TabMenuHorizontal>
  );
};
