import { Outlet, Navigate } from "react-router";
import useOptionalMerchant from "src/helpers/hooks/app/useOptionalMerchant";
import SubStoreScreen from "../components/SubStoreScreen";
import { buildPathWithMerchantId } from "../helpers/buildPathWithMerchantId";
import { ROUTES } from "../DepictLiteRouter-ROUTES";
import { useMultiStoreValidation } from "../helpers/useMultiStore";
import { TRIGGER_INSTALL_PATH_MERCHANT_ID } from "../views/GettingStarted/Ingestion/IngestionStep";

export default function MultiStoreBoundary() {
  const { isSubStore, merchantDomain, merchantId } = useMultiStoreValidation();

  if (isSubStore && merchantDomain && merchantId) {
    // Sub stores should not access this interface at all, point them towards their parent store
    return <SubStoreScreen merchantId={merchantId} domain={merchantDomain} />;
  }

  return <Outlet />;
}
