import { LiteColumnSettings } from "../../../../api/types";

export function setColumnSettingMetafieldData(
  columSettings: LiteColumnSettings
) {
  return {
    shop: {
      metafield: {
        value: JSON.stringify([
          columSettings.desktop_columns,
          columSettings.mobile_columns,
        ]),
      },
    },
  };
}
