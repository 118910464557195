import { css, useTheme } from "@emotion/react";
import { ProgressBar } from "../../../../alignUI/ProgressBar/ProgressBar";

interface IngestionProgressProps {
  completionPercentage: number;
}
export const IngestionProgress = (props: IngestionProgressProps) => {
  const theme = useTheme();
  const { completionPercentage } = props;

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
      `}
    >
      <ProgressBar
        width={"320px"}
        color={theme.colors.primary.base}
        percentage={completionPercentage}
      />
      <span
        css={[
          theme.typography["paragraph-m"],
          css`
            color: ${theme.colors.text["soft-400"]};
            text-align: center;
          `,
        ]}
      >
        Analysing your products and collections.
        <br />
        This could take up to 5 min.
      </span>
    </div>
  );
};
