import { useCallback } from "react";
import { AlertMessage } from "../Alert.types";
import { useNotification } from "src/alignUI/Notification/useNotification";

export function useAlert() {
  const { notification } = useNotification();

  const addAlert = useCallback(
    (alert: AlertMessage) => {
      notification(alert);
    },
    [notification]
  );

  return { addAlert };
}
