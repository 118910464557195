import { QueryKey } from "@tanstack/react-query";

// Query IDs are used to identify queries in the cache (when using react-query)
export enum QueryId {
  GetAuthorizedMerchants = "GetAuthorizedMerchants",
  GetMaintenance = "GetMaintenance",
  GetShopifyUrl = "GetShopifyUrl",
  GetIngestionRunLogs = "GetIngestionRunLogs",
}

const queryKeyBuilders = {
  [QueryId.GetAuthorizedMerchants]: () => [QueryId.GetAuthorizedMerchants],
  [QueryId.GetMaintenance]: () => [QueryId.GetMaintenance],
  [QueryId.GetShopifyUrl]: (merchantId: string) => [
    QueryId.GetShopifyUrl,
    merchantId,
  ],
  [QueryId.GetIngestionRunLogs]: (merchantId: string) => [
    QueryId.GetIngestionRunLogs,
    merchantId,
  ],
} as const;

type QueryKeyArgs = {
  [K in QueryId]: Parameters<(typeof queryKeyBuilders)[K]>;
};

export function getQueryKey<K extends QueryId>(
  queryId: K,
  ...args: QueryKeyArgs[K]
): QueryKey {
  // @ts-expect-error
  return queryKeyBuilders[queryId](...args);
}
