import {
  Navigate,
  useLocation,
  useMatches,
  useNavigate,
  useParams,
} from "react-router";
import { css } from "@emotion/react";
import { ProgressBar } from "src/alignUI/ProgressBar/ProgressBar";
import { CaretLeft } from "@phosphor-icons/react";
import { Button } from "src/alignUI/Button/Button";
import { motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { dragToSelectAndDeselectClass } from "../Collection/DragAndDrop/useDragToSelect";
import useOptionalMerchant from "../../../helpers/hooks/app/useOptionalMerchant";
import { ROUTES } from "../../DepictLiteRouter-ROUTES";
import { buildPathWithMerchantId } from "src/lite/helpers/buildPathWithMerchantId";

export const GettingStartedSteps = {
  Step1: "step-1",
  Step2: "step-2",
};

const GettingStartedStepsArray = Object.values(GettingStartedSteps);

export default function WizardLayout({
  buttons,
  onPreviousStep,
  children,
}: PropsWithChildren<{ buttons?: JSX.Element; onPreviousStep?: () => void }>) {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];

  const currentStepIndex = GettingStartedStepsArray.indexOf(lastMatch.id) + 1;

  const percentage =
    100.0 *
    (currentStepIndex !== -1
      ? currentStepIndex / GettingStartedStepsArray.length
      : 0);

  const { pathname } = useLocation();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100dvh;
      `}
    >
      <div
        css={css`
          padding: 16px 32px;
          display: flex;
          justify-content: space-between;
        `}
      >
        <div>
          {onPreviousStep && (
            <Button
              variant="neutral"
              _style="ghost"
              onClick={() => {
                onPreviousStep();
              }}
            >
              <CaretLeft size={20} />
              Back
            </Button>
          )}
        </div>

        <div>{buttons}</div>
      </div>
      <div
        css={css`
          margin: 0 32px;
        `}
      >
        <ProgressBar percentage={percentage} />
      </div>
      <div
        css={css`
          flex-grow: 1;
          padding: 0px 32px;
        `}
      >
        <motion.div
          className={dragToSelectAndDeselectClass}
          key={pathname}
          animate="in"
          exit="out"
          initial="initial"
          transition={{
            type: "spring",
            duration: 0.5,
            bounc: 0.25,
          }}
          css={css`
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            padding: 0 11px;
          `}
          variants={{
            initial: {
              opacity: 0,
              translateX: "20vw",
            },
            in: {
              opacity: 1,
              translateX: 0,
            },
            out: {
              opacity: 0,
              translateX: "-50vw",
            },
          }}
        >
          {children}
        </motion.div>
      </div>
    </div>
  );
}
