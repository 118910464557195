import {
  css,
  Interpolation,
  SerializedStyles,
  Theme,
  useTheme,
} from "@emotion/react";
import { CaretLeft } from "@phosphor-icons/react";
import { Link } from "react-router";
import React from "react";

export default function PageHeader({
  title,
  linkBackTo,
  children,
  wrapperCss,
  outerCss,
}: {
  title: React.ReactNode;
  linkBackTo?: string;
  children?: React.ReactNode;
  wrapperCss?: SerializedStyles;
  outerCss?: Interpolation<Theme>;
}) {
  const theme = useTheme();
  return (
    <div
      css={[
        css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-height: 40px;
          margin-bottom: 16px;
        `,
        outerCss,
      ]}
    >
      <div
        css={css`
          display: flex;
          gap: 32px;
          align-items: center;
        `}
      >
        {linkBackTo && (
          <div
            css={css`
              display: flex;
              cursor: pointer;
              line-height: 0;
            `}
          >
            <Link to={`${linkBackTo}`}>
              <CaretLeft size={20} color={theme.colors.icon["sub-600"]} />
            </Link>
          </div>
        )}
        <h1
          css={[
            theme.typography.h6,
            css`
              margin: 0;
              display: flex;
            `,
          ]}
        >
          {title}
        </h1>
      </div>
      <div css={wrapperCss}>{children}</div>
    </div>
  );
}
