import { useEffect, useRef } from "react";
import { UseInfiniteQueryResult } from "@tanstack/react-query";

export function usePercentageBasedInfiniteScroll<T, X>(
  infiniteQueryResult: Pick<
    UseInfiniteQueryResult<T, X>,
    "hasNextPage" | "isFetching"
  > & { fetchNextPage: () => unknown },
  numberOfFetchedItems: number
) {
  const { fetchNextPage, hasNextPage, isFetching } = infiniteQueryResult;
  const dataForObserverCallback = useRef({
    currentlyLoaded: 0,
    fetchNextPage,
    hasNextPage,
    isFetching,
    runAfterLoad: [] as VoidFunction[],
  });
  const elementByIndex = useRef(new Map<number, HTMLElement>());
  const indexByElement = useRef(new Map<HTMLElement, number>());

  const paginationIntersectionObserver = useRef(
    new IntersectionObserver((records) => {
      const data = dataForObserverCallback.current;
      records.forEach(async (record) => {
        if (!record.isIntersecting) return;
        // Always get the index directly since nodes might stop existing if we delay this
        const index = indexByElement.current.get(record.target as HTMLElement);
        if (index == undefined) {
          console.warn(
            "No index given for",
            record.target,
            "did you attach the ref"
          );
          return;
        }

        // Wait for us to be ready to load more before processing this record
        while (data.isFetching) {
          await new Promise<void>((r) => data.runAfterLoad.push(r));
        }

        const percentLoaded = index / data.currentlyLoaded;
        // At 75% of the way through the content, load more
        if (percentLoaded >= 0.75 && data.hasNextPage) {
          // Set isLoading to true instantly because the IS will trigger before slow react has set it to true at the next re-render
          data.isFetching = true;
          // Trigger fetch of next page
          data.fetchNextPage();
        }
      });
    })
  );

  // Update dataForObserverCallback
  Object.assign(dataForObserverCallback.current, {
    fetchNextPage,
    hasNextPage,
    isFetching,
    currentlyLoaded: numberOfFetchedItems,
  });

  useEffect(() => {
    if (isFetching) return;
    const data = dataForObserverCallback.current;
    data.runAfterLoad.forEach((fn) => fn());
    data.runAfterLoad = [];
  }, [isFetching]);

  const makeInfiniteRef = (index: number) => (el: HTMLElement | null) => {
    const elementByIndexMap = elementByIndex.current;
    const indexByElementMap = indexByElement.current;
    const observer = paginationIntersectionObserver.current;
    if (el) {
      elementByIndexMap.set(index, el);
      indexByElementMap.set(el, index);
      observer.observe(el);
    } else {
      const oldEl = elementByIndexMap.get(index);
      if (oldEl) {
        observer.unobserve(oldEl);
        indexByElementMap.delete(oldEl);
      }
      elementByIndexMap.delete(index);
    }
  };

  return { makeInfiniteRef };
}
