import { css } from "@emotion/react";
import { DepictSpinner } from "../DepictSpinner/DepictSpinner";

const SplashScreen = () => {
  return (
    <div
      css={css`
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      `}
    >
      <DepictSpinner />
    </div>
  );
};

export default SplashScreen;
