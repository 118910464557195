import { useQuery } from "@tanstack/react-query";
import { getLiteQueryKey, QueryId } from "../../lite/queries";
import useAuthorizedApi from "./app/useAuthorizedApi";
import { getData } from "../../api/authorizedApi";

export default function useUser() {
  const { api } = useAuthorizedApi();

  const query = useQuery({
    queryKey: getLiteQueryKey(QueryId.GetUser),
    queryFn: async () => {
      const res = await api.GET("/users/me");
      return getData(res);
    },
  });
  return {
    ...query,
    user: query.data,
  };
}
