import { Interpolation, Theme, css, useTheme } from "@emotion/react";

type TabMenuVerticalProps = React.PropsWithChildren<{
  customCss?: Interpolation<Theme>;
  className?: string;
}>;

export function TabMenuVertical({
  children,
  className,
  customCss,
}: TabMenuVerticalProps) {
  return (
    <ul
      className={className}
      css={[
        css`
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          margin: 0;
          padding: 0;
        `,
        customCss,
      ]}
    >
      {children}
    </ul>
  );
}

type TabMenuVerticalItemProps = React.PropsWithChildren<{
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  active?: boolean;
  customCss?: Interpolation<Theme>;
  size?: "large" | "small";
  className?: string;
}>;

const activeStyles = (theme: Theme) => css`
  background: ${theme.colors.bg["weak-50"]};
  color: ${theme.colors.text["strong-950"]};
`;

export function TabMenuVerticalItem({
  leftIcon,
  rightIcon,
  customCss,
  children,
  active,
  className,
  size = "large",
}: TabMenuVerticalItemProps) {
  const theme = useTheme();
  return (
    <li
      css={[
        css`
          display: flex;
          align-items: center;
          column-gap: 6px;
          margin: 0;
          border-radius: 8px;
        `,
        // size === "large" &&
        //   css`
        //     padding: 8px 0;
        //   `,
        active && activeStyles,
      ]}
    >
      {leftIcon}
      <div
        css={[
          size === "large"
            ? theme.typography["label-s"]
            : theme.typography["label-xs"],
          css`
            flex-grow: 1;
          `,
          customCss,
        ]}
        className={className}
      >
        {children}
      </div>
      {rightIcon}
    </li>
  );
}
