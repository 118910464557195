import { getAuthorizedApi } from "src/api/authorizedApi";
import { getAccessToken } from "src/helpers/hooks/app/useAuthentication";

export type AuthorizedApi = ReturnType<typeof getAuthorizedApi>;

function useAuthorizedApi(): { api: AuthorizedApi } {
  const api = getAuthorizedApi(getAccessToken);
  return { api };
}

export default useAuthorizedApi;
