import usePlanStatus from "../../../helpers/usePlanStatus";

export function useSubscriptionStatus() {
  const data = usePlanStatus();

  if (!data.subscription) {
    return { isPaid: false as const };
  }
  return {
    isPaid: true as const,
    ...data.subscription,
  };
}
