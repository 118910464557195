import {
  CursorClick,
  DropboxLogo,
  Lightning,
  PencilSimple,
  Star,
} from "@phosphor-icons/react";
import { ReactElement } from "react";
import { Badge } from "src/alignUI/Badges/Badge";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "src/alignUI/Tooltip/Tooltip";

type TopConvertingCollectionBadge = {
  type: "conversion_rate";
  rank: number;
  conversionRate: number;
};

type TopPopularCollectionBadge = {
  type: "popular";
  rank: number;
  clicks: number;
};

type OutOfStockCollectionBadge = {
  type: "out_of_stock";
  mainProductIds: string[];
};

type LiveBadge = {
  type: "live";
};
type DraftBadge = {
  type: "draft";
  text?: string;
};

export type CollectionBadge =
  | TopConvertingCollectionBadge
  | TopPopularCollectionBadge
  | OutOfStockCollectionBadge
  | LiveBadge
  | DraftBadge;

function CollectionBadgeComponent({ badge }: { badge: CollectionBadge }) {
  switch (badge.type) {
    case "live":
      return (
        <Badge
          _style="light"
          color="green"
          icon={<Lightning size={16} weight="fill" />}
          text="Live"
          size="medium"
        />
      );
    case "draft":
      return (
        <Badge
          _style="light"
          color="gray"
          icon={<PencilSimple size={16} weight="fill" />}
          text={badge.text || "Draft"}
          size="medium"
        />
      );
    case "conversion_rate":
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              _style={"lighter"}
              text={""}
              icon={<CursorClick size={16} weight={"fill"} />}
              color="blue"
              size="medium"
              className="lift"
            />
          </TooltipTrigger>
          <TooltipContent>
            Rank {badge.rank} with {badge.conversionRate.toFixed(0)}%
            clickthrough rate
          </TooltipContent>
        </Tooltip>
      );
    case "popular":
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              _style={"lighter"}
              text={""}
              icon={<Star size={16} weight={"fill"} />}
              color="blue"
              size="medium"
              className="lift"
            />
          </TooltipTrigger>
          <TooltipContent>
            Rank {badge.rank} with {badge.clicks} clicks
          </TooltipContent>
        </Tooltip>
      );
    case "out_of_stock":
      return (
        <Tooltip>
          <TooltipTrigger asChild>
            <Badge
              _style={"lighter"}
              text={""}
              icon={<DropboxLogo size={16} weight={"fill"} />}
              color="red"
              size="medium"
              className="lift"
            />
          </TooltipTrigger>
          <TooltipContent>
            {badge.mainProductIds.length} products out of stock in the first 16
            products
          </TooltipContent>
        </Tooltip>
      );
  }
}

function PlusBadge({ badges }: { badges: string[] }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Badge
          _style={"lighter"}
          text={"+" + badges.length.toString()}
          color="gray"
          size="medium"
          className="lift"
        />
      </TooltipTrigger>
      <TooltipContent>{badges.join(" | ")}</TooltipContent>
    </Tooltip>
  );
}

function getBadgeNames(badges: CollectionBadge[]): string[] {
  const badgeNames: string[] = [];

  badges.forEach((badge) => {
    switch (badge.type) {
      case "live":
        badgeNames.push(`Live`);
        break;
      case "draft":
        badgeNames.push(`Draft`);
        break;
      case "conversion_rate":
        badgeNames.push(`Converting`);
        break;
      case "popular":
        badgeNames.push(`Popular`);
        break;
      case "out_of_stock":
        badgeNames.push(`Issue stock`);
        break;
    }
  });
  return badgeNames;
}

export function BadgeGroup({ badges }: { badges: CollectionBadge[] }) {
  const elements: ReactElement[] = [];
  for (let i = 0; i < 2; i++) {
    if (!badges[i]) {
      break;
    }
    elements.push(
      <CollectionBadgeComponent badge={badges[i]} key={badges[i].type} />
    );
  }
  if (badges.length > 2) {
    elements.push(
      <PlusBadge badges={getBadgeNames(badges.slice(2))} key={"overflow"} />
    );
  }
  return elements;
}

interface CollectionStateBadgeProps {
  live: boolean;
  draft: boolean;
  draftText?: string;
}
export function CollectionStateBadge({
  live,
  draft,
  draftText,
}: CollectionStateBadgeProps) {
  // If we're Live, that's always the badge we want to show
  if (live) {
    return <CollectionBadgeComponent badge={{ type: "live" }} />;
  }

  // If we already have changes saved to our database, or if we have changes in the current session, then it's a draft collection
  if (draft) {
    return (
      <CollectionBadgeComponent badge={{ type: "draft", text: draftText }} />
    );
  }

  // Otherwise the collection is in its initial state and we show nothing
  return null;
}
