import { css } from "@emotion/react";
import { Info } from "@phosphor-icons/react";
import usePlanStatus from "src/lite/helpers/usePlanStatus";
import { useSubscriptionStatus } from "./useSubscriptionStatus";

export default function FreeSessionInfo() {
  const { proj_sessions, num_active_collections, max_sessions } =
    usePlanStatus();
  const { isPaid } = useSubscriptionStatus();

  const recommendedPlan =
    proj_sessions !== null
      ? proj_sessions > 25000
        ? proj_sessions > 250000
          ? "Pro"
          : "Essentials"
        : "Basic"
      : null;

  const estimatedSessions =
    proj_sessions !== null ? (
      <>
        {" "}
        We estimate your store to have <b>{kNumber(proj_sessions)}</b>{" "}
        sessions/month.
      </>
    ) : null;

  const recommendedPlanMessage =
    !isPaid && recommendedPlan ? (
      <>
        {" "}
        The <b>{recommendedPlan} plan</b> looks best for you.
      </>
    ) : null;

  const sessionLimit =
    max_sessions !== null ? (
      <>
        {" "}
        If you exceed session limits within the month, collections actions will
        be frozen. Session counts reset every month.
      </>
    ) : null;

  return (
    <div
      css={css`
        display: flex;
        align-items: flex-start;
        gap: 8px;
        justify-content: flex-start;
      `}
    >
      <div>
        <Info
          css={(theme) => css`
            color: ${theme.colors.state.information.base};
          `}
          weight={"fill"}
          size={16}
        />
      </div>
      <p
        css={(theme) => css`
          ${theme.typography["paragraph-s"]}
          color: ${theme.colors.text["strong-950"]};
        `}
      >
        You have <b>{num_active_collections}</b> live collection(s).
        {estimatedSessions}
        {recommendedPlanMessage}
        {sessionLimit}
      </p>
    </div>
  );
}

function kNumber(sessions: number) {
  if (sessions > 1000000) {
    return "~" + Math.round(sessions / 1000000).toFixed() + "m";
  }
  if (sessions > 1000) {
    return "~" + Math.round(sessions / 1000).toFixed() + "k";
  }
  return "less than 1k";
}
