import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import useAuthorizedApi from "../../../helpers/hooks/app/useAuthorizedApi";
import { useQuery } from "@tanstack/react-query";
import { getLiteQueryKey, QueryId } from "../../queries";
import { getData } from "src/api/authorizedApi";

export default function useGetCollectionsOutOfStockStatus() {
  const { merchantId } = useMerchantId();

  const { api } = useAuthorizedApi();

  return useQuery({
    queryKey: getLiteQueryKey(QueryId.GetCollectionsOutOfStockStatus, {
      merchantId,
    }),
    queryFn: async () => {
      const response = await api.GET(`/collections/out-of-stock-badge-data`, {
        params: {
          query: {
            merchant_id: merchantId,
          },
        },
      });

      return getData(response);
    },
    enabled: !!merchantId,
  });
}
