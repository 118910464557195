import { useMutation } from "@tanstack/react-query";
import { getData } from "src/api/authorizedApi";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import queryClient from "../../../queries/client";
import { getQueryKey, QueryId } from "../../../queries/queries";
import * as Sentry from "@sentry/react";

class MerchantAlreadyExists extends Error {
  constructor() {
    super("Merchant already exists");
    this.name = "MerchantAlreadyExists";
  }
}

export default function useInstall() {
  const { api } = useAuthorizedApi();
  return useMutation({
    async mutationFn() {
      const response = await api.POST("/merchant-create/", {});
      if (response.response.status === 409) {
        throw new MerchantAlreadyExists();
      }
      return getData(response);
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: getQueryKey(QueryId.GetAuthorizedMerchants),
      });
    },
    // when failing with a 409, we want to invalidate the authorized merchants query to load the merchant that apparently exists
    onError(error) {
      if (error instanceof MerchantAlreadyExists) {
        queryClient.invalidateQueries({
          queryKey: getQueryKey(QueryId.GetAuthorizedMerchants),
        });
      } else {
        Sentry.captureException(error);
      }
    },
  });
}
