import { css, useTheme } from "@emotion/react";
import { motion } from "framer-motion";
import { CSSProperties } from "react";

interface ProgressBarProps {
  width?: CSSProperties["width"];
  percentage: number;
  color?: string;
}

export const ProgressBar = ({ width, percentage, color }: ProgressBarProps) => {
  const theme = useTheme();
  const animationDuration = `${Math.abs(percentage) / 100}s`; // Adjust duration dynamically

  return (
    <div
      css={[
        width &&
          css`
            width: ${width};
            max-width: ${width};
          `,
        css`
          height: 6px;
          border-radius: 999px;
          background-color: ${theme.colors.bg["soft-200"]};
          position: relative;
          flex-grow: 1;
          overflow: hidden;
        `,
      ]}
    >
      <motion.div
        initial={false}
        animate={{ width: `${percentage}%` }}
        transition={{ duration: parseFloat(animationDuration) }}
        css={css`
          width: ${percentage}%;
          height: 100%;
          border-radius: 999px;
          background-color: ${!color
            ? theme.colors.state.information.base
            : color};
        `}
      />
    </div>
  );
};
