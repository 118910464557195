import * as Sentry from "@sentry/react";
import { Navigate } from "react-router";
import useOptionalMerchant from "src/helpers/hooks/app/useOptionalMerchant";
import { buildPathWithMerchantId } from "../helpers/buildPathWithMerchantId";
import SplashScreen from "../components/SplashScreen/SplashScreen";
import { ROUTES } from "../DepictLiteRouter-ROUTES";

export default function NavigateToCollections() {
  const { merchant } = useOptionalMerchant();

  if (!merchant) {
    // Logically this should never happen, but if someone changes the routing to end up here before merchant is loaded, better safe than sorry and wait for it maybe to load.
    // Definitely log the error so we can fix our routing.
    Sentry.captureException(
      new Error("Merchant should be loaded at this point")
    );
    return <SplashScreen />;
  }

  return (
    <Navigate
      replace
      to={buildPathWithMerchantId(ROUTES.COLLECTIONS, merchant.id)}
    />
  );
}
