import { useParams } from "react-router";
import { TRIGGER_INSTALL_PATH_MERCHANT_ID } from "src/lite/views/GettingStarted/Ingestion/IngestionStep";

export const useOptionalMerchantId = (): { merchantId: string | undefined } => {
  const { merchantId: merchantIdFromParamsRaw } = useParams();
  const merchantIdFromParams =
    merchantIdFromParamsRaw === TRIGGER_INSTALL_PATH_MERCHANT_ID
      ? undefined
      : merchantIdFromParamsRaw;

  return { merchantId: merchantIdFromParams };
};

/**
 * useMerchantId is a React hook that provides a convenient way to access the
 * merchantId from the URL.
 * Note: This is mainly used as a dependency for other `useMerchant`. It's recommended that you use `merchant?.id` throughout the app
 */
export const useMerchantId = (): { merchantId: string } => {
  const { merchantId } = useOptionalMerchantId();

  if (merchantId) {
    return { merchantId };
  } else {
    throw new Error("No merchantId found");
  }
};

export default useMerchantId;
