import { useMemo } from "react";
import { useDragAndDropContext } from "./DragAndDropProvider";
import { useContentBlocks } from "../../ContentBlock/useContentBlocks";
import { getSortByGridOrderPrioritiseBlocks } from "./useSortByGridOrder";
import { LiteCollectionProductDto } from "../../../../api/types";

export function useRowMode() {
  const {
    numberOfColumns,
    repositionedBlocksWithRender,
    isDragging,
    occupiedByBlocksOrProducts,
    productsToRender,
  } = useDragAndDropContext();

  return useMemo(() => {
    return isRowMode({
      isDragging,
      repositionedBlocksWithRender,
      numberOfColumns,
      occupiedByBlocksOrProducts,
      productsToRender,
    });
  }, [
    isDragging,
    numberOfColumns,
    occupiedByBlocksOrProducts,
    productsToRender,
    repositionedBlocksWithRender,
  ]);
}

export function isRowMode({
  isDragging,
  repositionedBlocksWithRender,
  numberOfColumns,
  occupiedByBlocksOrProducts,
  productsToRender,
}: {
  isDragging: Set<string>;
  repositionedBlocksWithRender: ReturnType<
    typeof useContentBlocks
  >["repositionedBlocksWithRender"];
  numberOfColumns: number;
  occupiedByBlocksOrProducts: (string | number | null)[];
  productsToRender: LiteCollectionProductDto[];
}) {
  // This function is very similar to useImportantDraggingSize, but can't use hooks here unfortunately, due to react hooks not being able to be used everywhere
  if (isDragging.size === 0) return false;

  const { allSortedPrioritised } = getSortByGridOrderPrioritiseBlocks(
    isDragging,
    occupiedByBlocksOrProducts,
    productsToRender
  );

  const firstId = allSortedPrioritised[0];
  const firstItemWidth = repositionedBlocksWithRender.find(
    (b) => b.block.DOMElementId === firstId
  )?.block.span_columns;

  return (firstItemWidth ?? 1) >= numberOfColumns;
}
