export function getOriginalProductIdForDuplicateProduct(
  productId: string
): string {
  return productId.split("_duplicate")[0];
}

export function getProductIdForDuplicateProduct(productId: string): string {
  return productId + "_duplicate";
}

export function isDuplicate(productId: string): boolean {
  return productId.endsWith("_duplicate");
}
