import { css, useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { dlog } from "src/helpers/dlog";
import CompactButton from "../../../alignUI/CompactButton/CompactButton";
import { Bell } from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../alignUI/Tooltip/Tooltip";

let jimoPromise: Promise<void> | undefined;

export function JimoBell() {
  const theme = useTheme();
  const [shouldShowNotificationDot, setShouldShowNotificationDot] =
    useState(false);

  // Only show unread dot if there are unread items in Jimo
  useEffect(() => {
    (async () => {
      // Wait for jimo to be ready. https://depictaiworkspace.slack.com/archives/C0146MYLW5V/p1724678199683099
      let currentJimo = (window as any)?.jimo;
      // Jimo not initialised yet, it's set to an array and they will switch that out for a new object later
      if (!currentJimo?.on) {
        const start = performance.now();
        await (jimoPromise ||= new Promise<void>((resolve) => {
          dlog("Waiting for Jimo to be ready");
          Object.defineProperty(window, "jimo", {
            configurable: false,
            enumerable: true,
            get: () => currentJimo,
            set: (value) => {
              currentJimo = value;
              if (value?.on) {
                resolve();
                dlog("JIMO ready after", performance.now() - start, "ms");
              }
            },
          });
        }));
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const count = (window as any).jimo?.push?.(["get", "widget:unreadCount"]);
      setShouldShowNotificationDot(count > 0);
    })();
  }, []);

  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <div
          css={css`
            display: flex;
            align-items: flex-start;
          `}
        >
          <CompactButton
            className="dashboard-bell-button"
            _style="stroke"
            size="large"
            icon={Bell}
            css={css`
              width: 32px;
              height: 32px;
              cursor: pointer;
            `}
            onClick={() =>
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (window as any).jimo.push(["do", "widget:open"])
            }
          />
          {shouldShowNotificationDot && (
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                margin-left: -9px;
                pointer-events: none;
              `}
            >
              <circle
                cx="5"
                cy="5"
                r="4.5"
                fill={theme.colors.state.error.base}
                stroke={theme.colors.bg["white-0"]}
              />
            </svg>
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>Show changelog</TooltipContent>
    </Tooltip>
  );
}
