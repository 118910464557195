export type DefaultPeriod = "24h" | "7d" | "4w" | "6m" | "1y";

export type Period = {
  type: DefaultPeriod | "custom";
  fromDate: Date;
  toDate: Date;
};

export function getFromPeriod(period: DefaultPeriod, now: Date) {
  switch (period) {
    case "24h":
      return new Date(now.getTime() - 24 * 60 * 60 * 1000);
    case "7d":
      return new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
    case "4w":
      return new Date(now.getTime() - 4 * 7 * 24 * 60 * 60 * 1000);
    case "6m":
      return new Date(now.getTime() - 6 * 30 * 24 * 60 * 60 * 1000);
    case "1y":
      return new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
  }
}
