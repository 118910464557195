import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { css, SerializedStyles, useTheme } from "@emotion/react";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

interface TooltipContentProps
  extends React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> {
  size?: "x-small" | "medium" | "large";
}

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  TooltipContentProps
>(({ className, sideOffset = 4, children, size, ...props }, ref) => {
  const theme = useTheme();
  return (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      css={[
        theme.typography["paragraph-s"],
        css`
          z-index: calc(var(--dnd-base-z-index, 0) + 1500);
          overflow: hidden;
          border-radius: 6px;
          padding: 4px 10px;
          color: ${theme.colors.text["strong-950"]};

          ${size === "x-small" &&
          css`
            max-width: 106px;
          `}
          ${size === "medium" &&
          css`
            max-width: 186px;
          `}
          ${size === "large" &&
          css`
            max-width: 280px;
          `}


          border: 1px solid ${theme.colors.stroke["soft-200"]};
          background: ${theme.colors.bg["white-0"]};
          box-shadow:
            0 12px 24px 0 rgba(14, 18, 27, 0.06),
            0 1px 2px 0 rgba(14, 18, 27, 0.03);

          animation: tooltipEnter 95ms ease-out;

          &[data-state="closed"] {
            animation: tooltipExit 95ms ease-out;
          }

          @keyframes tooltipEnter {
            from {
              opacity: 0;
              transform: scale(0.96);
            }
            to {
              opacity: 1;
              transform: scale(1);
            }
          }

          @keyframes tooltipExit {
            from {
              opacity: 1;
              transform: scale(1);
            }
            to {
              opacity: 0;
              transform: scale(0.96);
            }
          }
        `,
      ]}
      {...props}
    >
      {children}
    </TooltipPrimitive.Content>
  );
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
