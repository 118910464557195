import { css, SerializedStyles, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { PropsWithChildren } from "react";

interface PlaceholderProps {
  height?: string | number;
  width?: string | number;
  extraCss?: SerializedStyles;
}
export const Placeholder = (props: PropsWithChildren<PlaceholderProps>) => {
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          height: ${props.height || "16px"};
          width: ${props.width || "16px"};
          align-self: stretch;
          border-radius: 4px;
          background: ${theme.colors.bg["weak-50"]};

          /* animate skeleton */
          animation: pulse 1.5s infinite;
          @keyframes pulse {
            0% {
              background: ${theme.colors.bg["weak-50"]};
            }
            50% {
              background: ${theme.colors.bg["sub-200"]};
            }
            100% {
              background: ${theme.colors.bg["weak-50"]};
            }
          }
        `,
        props.extraCss,
      ]}
    >
      {props.children}
    </div>
  );
};

export const TextPlaceholder = styled.span`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.bg["weak-50"]};

  color: transparent;

  /* animate skeleton */
  animation: pulse 1.5s infinite;
  @keyframes pulse {
    0% {
      background: ${({ theme }) => theme.colors.bg["weak-50"]};
    }
    50% {
      background: ${({ theme }) => theme.colors.bg["sub-200"]};
    }
    100% {
      background: ${({ theme }) => theme.colors.bg["weak-50"]};
    }
  }
`;
