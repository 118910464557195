import { Button } from "src/alignUI/Button/Button";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalClose,
} from "src/alignUI/Modal/Modal";
import Plans from "../Plans";
import { Theme, css, useTheme } from "@emotion/react";
import FreeTrial from "../FreeTrial";
import { Link } from "react-router";
import { ROUTES } from "src/lite/DepictLiteRouter-ROUTES";
import { buildPathWithMerchantId } from "src/lite/helpers/buildPathWithMerchantId";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";
import { TabMenuVerticalItem } from "src/alignUI/TabMenuVertical/TabMenuVertical";
import { RocketLaunch, XCircle } from "@phosphor-icons/react";
import usePlanStatus from "src/lite/helpers/usePlanStatus";
import FreeSessionInfo from "../FreeSessionInfo";

const modalTitleStyles = (theme: Theme) => [
  theme.typography["h5"],
  css`
    color: ${theme.colors.text["strong-950"]};
    text-align: center;
    padding-top: 48px;
  `,
];

const modalParagraphStyles = (theme: Theme) => [
  theme.typography["paragraph-xs"],
  css`
    color: ${theme.colors.text["sub-600"]};
    text-align: center;
  `,
];

export function TrialModal({
  open,
  onClose,
}: {
  open?: boolean;
  onClose: () => void;
}) {
  const planStatus = usePlanStatus();
  const onTrial = planStatus.is_trial;
  const trialDaysLeft = planStatus.trial_days_left || 0;

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} onOpenChange={onClose}>
      <ModalContent>
        <ModalHeader
          css={css`
            border-bottom: none;
          `}
        >
          {onTrial ? TrailModalHeader(trialDaysLeft) : <ExpiredModalHeader />}
        </ModalHeader>
        {onTrial ? <TrialModalBody /> : <ExpiredModalBody onClose={onClose} />}
        {onTrial ? <TrialModalFooter /> : <ExpiredModalFooter />}
      </ModalContent>
    </Modal>
  );
}

function TrailModalHeader(trial_days_left: number) {
  return (
    <>
      <h1
        css={modalTitleStyles}
      >{`Your Unlimited Trial expires in ${trial_days_left === 1 ? "1 day" : `${trial_days_left} days`}`}</h1>
      <p css={modalParagraphStyles}>
        Choose the right plan for you in the Subscription Settings.
        <br />
        The Free Plan will be automatically selected for you once the trial
        ends.
      </p>
    </>
  );
}

function TrialModalFooter() {
  const { merchantId } = useMerchantId();
  return (
    <ModalFooter>
      <ModalClose asChild>
        <Button _style="stroke" variant="neutral">
          Close
        </Button>
      </ModalClose>
      <ModalClose asChild>
        <Button _style="filled" variant="primary" asChild>
          <Link to={buildPathWithMerchantId(ROUTES.SETTINGS, merchantId)}>
            Go to Subscription
          </Link>
        </Button>
      </ModalClose>
    </ModalFooter>
  );
}

function TrialModalBody() {
  const planStatus = usePlanStatus();
  return (
    <ModalBody
      css={css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
      `}
    >
      <div
        css={css`
          grid-column: span 3;
        `}
      >
        <FreeTrial daysLeft={planStatus.trial_days_left ?? 0} />
      </div>
      <Plans hideActions />
    </ModalBody>
  );
}

function ExpiredModalHeader() {
  const theme = useTheme();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `}
    >
      <h1 css={modalTitleStyles}>{`Unlimited Trial Expired`}</h1>
      <div
        css={css`
          display: flex;
          height: 80px;
          padding: 16px;
          align-items: flex-start;
          gap: 16px;
          align-self: center;
          width: fit-content;

          border-radius: 8px;
          background: ${theme.colors.state.warning.lighter};
        `}
      >
        <div
          css={css`
            display: flex;
            gap: 24px;
          `}
        >
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <RocketLaunch size={24} color={theme.colors.state.warning.dark} />
            <div>
              <h1
                css={(theme) => css`
                  ${theme.typography["label-s"]}
                  color: ${theme.colors.text["strong-950"]};
                `}
              >
                Unlimited Trial
              </h1>
              <p
                css={(theme) => css`
                  ${theme.typography["paragraph-xs"]}
                  color: ${theme.colors.state.warning.dark};
                `}
              >
                14 days expired
              </p>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              gap: 8px;
            `}
          >
            <TabMenuVerticalItem
              leftIcon={
                <XCircle
                  css={(theme) => css`
                    color: ${theme.colors.state.warning.dark};
                  `}
                />
              }
              css={(theme) => css`
                color: ${theme.colors.state.warning.dark};
                text-decoration: line-through;
              `}
            >
              Unlimited sessions
            </TabMenuVerticalItem>
            <TabMenuVerticalItem
              leftIcon={
                <XCircle
                  css={(theme) => css`
                    color: ${theme.colors.state.warning.dark};
                  `}
                />
              }
              css={(theme) => css`
                color: ${theme.colors.state.warning.dark};
                text-decoration: line-through;
              `}
            >
              Unlimited collections
            </TabMenuVerticalItem>
          </div>
        </div>
      </div>
    </div>
  );
}

function ExpiredModalFooter() {
  const theme = useTheme();
  return (
    <ModalFooter
      css={css`
        padding: 16px 32px;
        align-self: stretch;
        width: 100%;
        border-radius: 0px 0px 16px 16px;
        border-top: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["weak-50"]};
      `}
    >
      <FreeSessionInfo />
    </ModalFooter>
  );
}

function ExpiredModalBody({ onClose }: { onClose: () => void }) {
  const { merchantId } = useMerchantId();
  return (
    <ModalBody
      css={css`
        display: flex;
        flex-direction: column;
        gap: 20px;
      `}
    >
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 8px;
        `}
      >
        <Plans
          hideActions
          showSubscribedBadgeFree={true}
          customCssProPlan={css`
            grid-column: span 2;
          `}
        />
        <Button
          onClick={onClose}
          _style="filled"
          variant="primary"
          customCss={css`
            justify-self: center;
            align-self: center;
            padding: 10px;
          `}
          asChild
        >
          <Link to={buildPathWithMerchantId(ROUTES.SETTINGS, merchantId)}>
            Go to subscription
          </Link>
        </Button>
      </div>
    </ModalBody>
  );
}
