import { Link } from "react-router";
import { buildPathWithMerchantId } from "src/lite/helpers/buildPathWithMerchantId";
import { ROUTES } from "src/lite/DepictLiteRouter-ROUTES";
import type {} from "@shopify/app-bridge-types";

export function ShopifyNavMenu({ merchantId }: { merchantId: string }) {
  return (
    <ui-nav-menu>
      <Link rel="home" to={ROUTES.HOME}>
        Depict
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.COLLECTIONS, merchantId)}>
        Collections
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.GUIDES, merchantId)}>
        Help & Inspiration
      </Link>
      <Link to={buildPathWithMerchantId(ROUTES.SETTINGS, merchantId)}>
        Settings
      </Link>
    </ui-nav-menu>
  );
}
