import { useQuery } from "@tanstack/react-query";
import { getData } from "../../api/authorizedApi";
import useAuthorizedApi from "../../helpers/hooks/app/useAuthorizedApi";
import { getQueryKey, QueryId } from "../../queries/queries";
import useTrackIngestionComplete from "../views/GettingStarted/Ingestion/useTrackIngestionComplete";
import { useState } from "react";

interface UseIngestionRunLogs {
  merchantId: string | null | undefined;
  onIngestionComplete?: () => void;
}

export const useIngestionStatus = ({
  merchantId,
  onIngestionComplete,
}: UseIngestionRunLogs) => {
  const { api } = useAuthorizedApi();

  const { trackIngestionComplete } = useTrackIngestionComplete(merchantId);
  const [disableIngestionRunLogs, setDisableIngestionRunLogs] = useState(false);

  const query = useQuery({
    queryKey: getQueryKey(QueryId.GetIngestionRunLogs, merchantId || ""),
    queryFn: async () => {
      if (!merchantId) return;

      const response = await api.GET(
        "/merchants/{merchant_id}/ingestion-status",
        {
          params: {
            path: {
              merchant_id: merchantId,
            },
          },
        }
      );

      const data = getData(response);

      if (data?.has_run_at_least_one_ingestion) {
        onIngestionComplete?.();
        trackIngestionComplete();
        setDisableIngestionRunLogs(true);
      }

      return data;
    },
    enabled: !!merchantId && !disableIngestionRunLogs,
    refetchInterval: 1000,
    refetchIntervalInBackground: true,
  });

  return {
    complete: query.data?.has_run_at_least_one_ingestion,
    completionPercentage: query.data?.completion_percentage || 0,
  };
};
