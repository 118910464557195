import { Size, Status, Style } from "./Alert.types";
import {
  CheckCircle,
  Icon,
  Info,
  Star,
  Warning,
  WarningCircle,
} from "@phosphor-icons/react";
import { css, Interpolation, Theme, useTheme } from "@emotion/react";

export const getIcon = (status: Status): Icon => {
  switch (status) {
    case "error":
      return WarningCircle;
    case "warning":
      return Warning;
    case "success":
      return CheckCircle;
    case "information":
      return Info;
    case "feature":
      return Star;
  }
};

export const getIconColor = (status: Status, theme: Theme): string => {
  switch (status) {
    case "error":
      return theme.colors.state.error.base;
    case "warning":
      return theme.colors.state.warning.base;
    case "success":
      return theme.colors.state.success.base;
    case "information":
      return theme.colors.state.information.base;
    case "feature":
      return theme.colors.state.faded.base;
  }
};

type StatusConfig = Record<Status, { icon: Icon; color: string }>;

type Config = {
  status: StatusConfig;
  size: {
    [key in Size]: {
      padding: string;
      gap: string;
      borderRadius: string;
      alignItems: string;
      closeIconSize: string;
      text: {
        layout: Interpolation<Theme>;
        typography: Interpolation<Theme>;
      };
    };
  };
  style: {
    [key in Style]: {
      backgroundColor: string;
      boxShadow: string | undefined;
      iconColor: string;
      textColor: string;
      border: string | undefined;
    };
  };
};

const useGetProps = (status: Status, size: Size, style: Style) => {
  const theme = useTheme();

  const statusConfig: StatusConfig = {
    error: {
      icon: getIcon("error"),
      color: getIconColor("error", theme),
    },
    warning: {
      icon: getIcon("warning"),
      color: getIconColor("warning", theme),
    },
    success: {
      icon: getIcon("success"),
      color: getIconColor("success", theme),
    },
    information: {
      icon: getIcon("information"),
      color: getIconColor("information", theme),
    },
    feature: {
      icon: getIcon("feature"),
      color: getIconColor("feature", theme),
    },
  };

  // Define a single configuration object
  const config: Config = {
    status: statusConfig,
    size: {
      "x-small": {
        padding: "8px",
        gap: "8px",
        borderRadius: "8px",
        alignItems: "center",
        closeIconSize: "16px",
        text: {
          layout: css`
            align-self: stretch;
            flex: 1;
            display: flex;
            align-items: center;
          `,
          typography: theme.typography["paragraph-xs"],
        },
      },
      small: {
        padding: "8px 10px",
        gap: "8px",
        borderRadius: "8px",
        alignItems: "center",
        closeIconSize: "20px",
        text: {
          layout: css`
            flex: 1;
          `,
          typography: theme.typography["paragraph-s"],
        },
      },
      large: {
        padding: "14px 14px 16px 14px",
        gap: "12px",
        borderRadius: "12px",
        alignItems: "flex-start",
        closeIconSize: "20px",
        text: {
          layout: css`
            align-self: stretch;
            flex: 1;
          `,
          typography: theme.typography["label-s"],
        },
      },
    },
    style: {
      filled: {
        iconColor: theme.colors.bg["white-0"],
        backgroundColor: statusConfig[status].color,
        textColor: theme.colors.text["white-0"],
        boxShadow: undefined,
        border: undefined,
      },
      light: {
        iconColor: statusConfig[status].color,
        backgroundColor: theme.colors.state[status]["light"],
        textColor: theme.colors.text["strong-950"],
        boxShadow: undefined,
        border: undefined,
      },
      lighter: {
        iconColor: statusConfig[status].color,
        backgroundColor: theme.colors.state[status]["lighter"],
        textColor: theme.colors.text["strong-950"],
        boxShadow: undefined,
        border: undefined,
      },
      stroke: {
        iconColor: statusConfig[status].color,
        backgroundColor: theme.colors.bg["white-0"],
        textColor: theme.colors.text["strong-950"],
        boxShadow: theme.shadows.regular.medium,
        border: `1px solid ${theme.colors.stroke["soft-200"]}`,
      },
    },
  };

  const sizeConfig = config.size[size];
  const styleConfig = config.style[style];

  return {
    container: {
      iconColor: styleConfig.iconColor,
      backgroundColor: styleConfig.backgroundColor,
      boxShadow: styleConfig.boxShadow,
      padding: sizeConfig.padding,
      gap: sizeConfig.gap,
      borderRadius: sizeConfig.borderRadius,
      textColor: styleConfig.textColor,
      alignItems: sizeConfig.alignItems,
      border: styleConfig.border,
    },
    icons: {
      leftIcon: statusConfig[status].icon,
      size: sizeConfig.closeIconSize,
      color: styleConfig.iconColor,
    },
    text: {
      layout: sizeConfig.text.layout,
      typography: sizeConfig.text.typography,
    },
  };
};

export default useGetProps;
