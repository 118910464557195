"use client";

import { css } from "@emotion/react";
import { Slot } from "@radix-ui/react-slot";
import { PropsWithChildren, forwardRef } from "react";

export interface LinkOverlayProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  asChild?: boolean;
  customCss?: ReturnType<typeof css>;
  external?: boolean;
}

export const LinkOverlay = forwardRef<
  HTMLAnchorElement,
  PropsWithChildren<LinkOverlayProps>
>(({ external, target, rel, customCss, children, asChild, ...rest }, ref) => {
  const Comp = asChild ? Slot : "a";

  return (
    <Comp
      {...rest}
      children={children}
      ref={ref}
      rel={external ? "noopener noreferrer" : rel}
      target={external ? "_blank" : target}
      className="linkbox__overlay"
      css={[
        {
          position: "static",
          "&::before": {
            content: "''",
            cursor: "inherit",
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            height: "100%",
          },
        },
        customCss,
      ]}
    />
  );
});

export interface LinkBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  customCss?: ReturnType<typeof css>;
}

export const LinkBox = forwardRef<HTMLDivElement, LinkBoxProps>(
  function LinkBox(props, ref) {
    const { customCss, ...rest } = props;

    return (
      <div
        ref={ref}
        {...rest}
        css={[
          {
            position: "relative",
            "& a[href]:not(.linkbox__overlay), abbr[title], button": {
              position: "relative",
              zIndex: 1,
            },
          },
          customCss,
        ]}
      />
    );
  }
);
