export function Wordmark({
  width = 140,
  height = 44,
}: {
  width?: number;
  height?: number;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 140 44`}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Depict wordmark">
        <path
          id="Vector"
          d="M137.848 30.4842C138.637 30.4842 139.032 30.4349 139.969 30.2869V35.2197C138.44 35.5649 137.256 35.7129 135.875 35.7129C130.844 35.7129 128.427 33.5425 128.427 27.4753V15.2421H124.875V10.0627H128.427V2.61426H135.184V10.0627H139.723V15.2421H135.184V27.4259C135.184 30.0896 136.122 30.4842 137.848 30.4842Z"
        />
        <path
          id="Vector_2"
          d="M111.65 36.0582C103.856 36.0582 98.874 30.5335 98.874 22.6412C98.874 14.7981 103.856 9.22412 111.551 9.22412C118.506 9.22412 122.896 13.5156 123.538 19.3856H116.681C116.435 17.1165 115.349 14.4528 111.65 14.4528C107.605 14.4528 105.829 17.9057 105.829 22.6412C105.829 27.3766 107.556 30.8295 111.65 30.8295C115.3 30.8295 116.435 28.3138 116.681 25.7981H123.538C123.143 31.7667 118.605 36.0582 111.65 36.0582Z"
        />
        <path
          id="Vector_3"
          d="M96.4376 6.51118H89.4331V0.147949H96.4376V6.51118ZM96.2896 35.269H89.5318V10.0627H96.2896V35.269Z"
        />
        <path
          id="Vector_4"
          d="M75.7771 9.27344C82.3377 9.27344 86.8758 14.3542 86.8758 22.6412C86.8758 30.9282 82.3377 36.0089 75.7771 36.0089C71.9789 36.0089 69.4139 34.1344 68.2794 32.556H68.1807V43.9999H61.4229V10.0627H68.1807V12.7264H68.2794C69.4139 11.2465 71.9789 9.27344 75.7771 9.27344ZM74.1 30.8295C77.8489 30.8295 79.8713 27.5739 79.8713 22.6412C79.8713 17.7084 77.8489 14.5021 74.1 14.5021C70.4498 14.5021 68.2794 17.4618 68.2794 22.6412C68.2794 27.8699 70.4498 30.8295 74.1 30.8295Z"
        />
        <path
          id="Vector_5"
          d="M58.7869 23.0358V24.4663H40.1905C40.2398 28.2152 42.7062 30.8788 46.4057 30.8788C49.8093 30.8788 51.1905 29.0044 51.6838 27.5739H58.5403C57.1591 32.6053 53.1636 36.0582 46.2084 36.0582C38.1187 36.0582 33.3833 30.4842 33.3833 22.6412C33.3833 15.0447 38.0201 9.22412 46.2084 9.22412C54.4461 9.22412 58.7869 14.3048 58.7869 23.0358ZM40.1905 19.9775H51.9304C51.9304 16.4752 49.7107 14.3048 46.1591 14.3048C42.8048 14.3048 40.4864 16.4259 40.1905 19.9775Z"
        />
        <path
          id="Vector_6"
          d="M15.9248 35.2691H0.731934V0H15.5301C25.3463 0 31.2656 6.3139 31.2656 17.6592C31.2656 29.0045 25.6916 35.2691 15.9248 35.2691ZM8.32835 28.9552H14.7902C21.3015 28.9552 23.4719 24.2197 23.4719 17.6592C23.4719 11.0987 21.3015 6.31391 14.7902 6.31391H8.32835V28.9552Z"
        />
      </g>
    </svg>
  );
}
