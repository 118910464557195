import { css, SerializedStyles, useTheme } from "@emotion/react";
import { getIcon, getIconColor } from "../Alert/Alert.helper";
import { X } from "@phosphor-icons/react";

export interface BannerProps {
  status: "information" | "error" | "warning";
  _style: "filled" | "lighter";
  title: string;
  description?: string;
  extraCss?: SerializedStyles;
  withIcon?: boolean;
  onClose?: () => void | null;
  component?: React.ReactNode;
  iconOverride?: React.ReactNode;
  expand?: boolean;
  roundedCorners?: boolean;
  closePadding?: boolean;
}

export default function Banner({
  title,
  description,
  extraCss,
  _style,
  status,
  withIcon = false,
  onClose = undefined,
  component,
  iconOverride,
  expand,
  roundedCorners,
  closePadding,
}: BannerProps) {
  const theme = useTheme();

  const backgroundColor = () => {
    switch (_style) {
      case "filled":
        return theme.colors.state[status].base;
      case "lighter":
        return theme.colors.state[status].lighter;
    }
  };

  const color = () => {
    if (_style === "filled") return theme.colors.neutral.static.white;

    if (_style === "lighter") return theme.colors.text["strong-950"];
  };

  const Icon = getIcon(status);

  const iconColor = getIconColor(status, theme);

  return (
    <div
      css={[
        theme.typography["label-s"],
        css`
          padding: 12px ${closePadding ? "12px" : "48px"};
          background: ${backgroundColor()};
          color: ${color()};
          display: flex;
          gap: 8px;
          justify-content: center;
          align-items: center;
          border-radius: ${roundedCorners ? "8px" : "0"};

          @media (max-width: ${theme.breakpoints.sm}px) {
            padding: 12px 24px;
            flex-direction: column;
            gap: 4px;

            .banner-separator {
              display: none;
            }
          }
        `,
        expand &&
          css`
            width: 100%;
          `,
        extraCss,
      ]}
    >
      <div
        css={css`
          display: flex;
          gap: 12px;
          align-items: center;
          white-space: nowrap;
        `}
      >
        {withIcon && !iconOverride && (
          <Icon size={20} color={iconColor} weight="fill" />
        )}
        {withIcon && iconOverride && iconOverride}
        <span>{title}</span>
      </div>
      {description && (
        <>
          <span className="banner-separator">&#8729;</span>
          <span
            css={[
              theme.typography["label-s"],
              css`
                color: ${color()};
              `,
            ]}
          >
            {description}
          </span>
        </>
      )}
      {component}
      {onClose && (
        <X
          size={20}
          color={theme.colors.icon["strong-950"]}
          css={css`
            cursor: pointer;
          `}
          opacity={0.72}
          onClick={onClose}
        />
      )}
    </div>
  );
}
