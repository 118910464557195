import { Button } from "../../../../alignUI/Button/Button";
import { css, useTheme } from "@emotion/react";
import { Collection } from "./CollectionTable.types";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";
import { ChartLine, DotsThreeVertical, Pause } from "@phosphor-icons/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../../../alignUI/Dropdown/Dropdown";
import { useState } from "react";

export const ActionButtons = ({
  collection,
  setSelectedCollectionId,
  onUnPublishDepict,
  className,
}: {
  collection: Collection;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  className?: string;
}) => {
  const isMobile = useIsMobile();
  return (
    <div
      className={className}
      css={css`
        justify-content: end;
        grid-column: 6/7;
        display: flex;
        visibility: hidden;
        align-items: baseline;
      `}
    >
      <Button
        _style="ghost"
        size={"xx-small"}
        variant={"neutral"}
        onClick={() => {
          setSelectedCollectionId(collection.id);
        }}
      >
        <ChartLine size={20} />
      </Button>
      {!isMobile && (
        <OverFlowMenuDropDown
          onUnPublishDepict={
            collection.syncBackToShopify
              ? () => onUnPublishDepict(collection.id)
              : undefined
          }
        />
      )}
    </div>
  );
};

const OverFlowMenuDropDown = ({
  onUnPublishDepict,
}: {
  onUnPublishDepict: (() => void) | undefined;
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  const theme = useTheme();

  return (
    <DropdownMenu open={openDropdown} onOpenChange={setOpenDropdown}>
      <DropdownMenuTrigger asChild>
        <Button
          _style="ghost"
          size={"xx-small"}
          variant={"neutral"}
          onClick={() => {
            setOpenDropdown(!openDropdown);
          }}
        >
          <DotsThreeVertical size={20} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Options</DropdownMenuLabel>
        <DropdownMenuItem asChild>
          <Button
            css={[
              theme.typography["paragraph-s"],
              css`
                display: flex;
                align-items: center;
                justify-content: left;
              `,
            ]}
            disabled={!onUnPublishDepict}
            onClick={onUnPublishDepict}
            variant={"danger"}
            _style={"ghost"}
          >
            <Pause size={20} />
            Un-publish
          </Button>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
