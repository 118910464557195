import { IconProps } from "@phosphor-icons/react";
import { css, useTheme } from "@emotion/react";

export const EmptyState = (props: {
  message: string;
  icon: React.FC<IconProps>;
}) => {
  const theme = useTheme();
  const Icon = props.icon;

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 4px;
      `}
    >
      <div
        css={css`
          display: flex;
          width: 148px;
          height: 148px;
          padding: 34px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 999px;
          border: 1px dashed ${theme.colors.stroke["soft-200"]};
          background: ${theme.colors.bg["weak-50"]};
        `}
      >
        <Icon
          size={80}
          css={css`
            color: ${theme.colors.icon["disabled-300"]};
          `}
        />
      </div>
      <p
        css={[
          theme.typography["label-s"],
          css`
            color: ${theme.colors.text["disabled-300"]};
          `,
        ]}
      >
        {props.message}
      </p>
    </div>
  );
};
