import { css } from "@emotion/react";
import { RocketLaunch, CheckCircle } from "@phosphor-icons/react";
import {
  TabMenuVertical,
  TabMenuVerticalItem,
} from "src/alignUI/TabMenuVertical/TabMenuVertical";

export default function FreeTrial({ daysLeft }: { daysLeft: number }) {
  return (
    <div
      css={(theme) => css`
        background-color: ${theme.colors.primary.alpha10};
        border: 1px solid ${theme.colors.primary.base};
        border-radius: 8px;
        padding: 16px;
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}
    >
      <div
        css={css`
          display: flex;
          gap: 8px;
        `}
      >
        <RocketLaunch
          size={20}
          css={(theme) => `color: ${theme.colors.primary.base}`}
        />
        <div css={(theme) => theme.typography["label-s"]}>
          You are on Unlimited Trial.
          <br />
          <span
            css={[
              (theme) => theme.typography["paragraph-xs"],
              (theme) => css`
                color: ${theme.colors.primary.base};
              `,
            ]}
          >
            {daysLeft}/14 days left
          </span>
        </div>
      </div>
      <TabMenuVertical>
        <TabMenuVerticalItem leftIcon={<CheckCircle size={20} />}>
          Unlimited sessions
        </TabMenuVerticalItem>
        <TabMenuVerticalItem leftIcon={<CheckCircle size={20} />}>
          Unlimited collections
        </TabMenuVerticalItem>
      </TabMenuVertical>
    </div>
  );
}
