import { css, Interpolation, Theme } from "@emotion/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { ComponentProps, ReactNode, forwardRef } from "react";

/**
 * Does not implement an actual Radix UI Tabs component, but rather a RadioGroup component styled to look like tabs.
 * This seems to be a more common use case.
 */
export const TabMenuHorizontal = forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadioGroup.Root>
>(function TabMenuHorizontal(props, ref) {
  return (
    <RadioGroup.Root
      ref={ref}
      {...props}
      css={css`
        display: flex;
        gap: 24px;
      `}
    />
  );
});

export const TabMenuHorizontalItem = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof RadioGroup.Item> & {
    leftIcon?: ReactNode;
    rightIcon?: ReactNode;
    number?: ReactNode;
    extraCss?: Interpolation<Theme>;
  }
>(function TabMenuHorizontalItem(
  { leftIcon, rightIcon, children, number, ...props },
  ref
) {
  return (
    <RadioGroup.Item
      ref={ref}
      {...props}
      css={[
        (theme) => theme.typography["label-s"],
        (theme) => css`
          display: flex;
          gap: 6px;
          align-items: center;
          appearance: none;
          border: 0;
          background: none;
          padding: 9px 0 7px 0;
          cursor: pointer;

          border-bottom: 2px solid transparent;
          color: ${theme.colors.text["sub-600"]};
          &[data-state="checked"] {
            border-bottom: 2px solid ${theme.colors.primary.base};
          }
          :hover,
          &[data-state="checked"] {
            color: ${theme.colors.text["strong-950"]};
          }
        `,
        props.extraCss,
      ]}
    >
      {leftIcon}
      {number ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}
        >
          {children}
          {number}
        </div>
      ) : (
        children
      )}
      {rightIcon}
    </RadioGroup.Item>
  );
});
