import { css, Interpolation, Theme } from "@emotion/react";
import { Dispatch, SetStateAction } from "react";
import { Badge } from "src/alignUI/Badges/Badge";
import { DepictLink } from "src/alignUI/DepictLink/DepictLink";
import { Plan, featuresByPlan } from "src/alignUI/Plans/Plans";
import { AppPricingInterval } from "src/generated/graphql-admin-api";
import useIsMobile from "src/helpers/hooks/useIsMobile";
import {
  planTypeToPrice,
  planTypeToAnnualPrice,
  planNameById,
} from "src/lite/helpers/plans";

interface PlansProps {
  setShowFreeModal?: Dispatch<SetStateAction<boolean>>;
  changeTo?: (plan: "basic" | "essentials") => void;
  pending?: boolean;
  hideActions?: boolean;
  interval?: AppPricingInterval;
  currentInterval?: AppPricingInterval;
  onFree?: boolean;
  onEssentials?: boolean;
  onBasic?: boolean;
  onPro?: boolean;
  showSubscribedBadgeFree?: boolean;
  customCssProPlan?: Interpolation<Theme>;
}

export default function Plans({
  interval,
  currentInterval,
  changeTo,
  pending,
  hideActions,
  onFree,
  onEssentials,
  onBasic,
  setShowFreeModal,
  showSubscribedBadgeFree,
  customCssProPlan,
}: PlansProps) {
  const isMobile = useIsMobile();
  return (
    <>
      <Plan
        size="small"
        hidePrice={hideActions}
        monthlyPrice={planTypeToPrice["free"]}
        annualPrice={planTypeToAnnualPrice["free"]}
        planName={planNameById["free"]}
        features={featuresByPlan["free"]}
        showSubscribedBadge={showSubscribedBadgeFree}
        buttons={
          hideActions || (
            <>
              {!onFree && (
                <DepictLink
                  _style="Primary"
                  disabled={pending}
                  onClick={() => setShowFreeModal?.(true)}
                >
                  Choose
                </DepictLink>
              )}
              {onFree && (
                <Badge color="gray" _style="light" text="Subscribed" />
              )}
            </>
          )
        }
        customCss={[
          (onFree || showSubscribedBadgeFree) &&
            ((theme) => css`
              background: ${theme.colors.bg["weak-50"]};
            `),
        ]}
        active={onFree}
      />
      <Plan
        size="small"
        hidePrice={hideActions}
        monthlyPrice={planTypeToPrice["Basic"]}
        annualPrice={planTypeToAnnualPrice["Basic"]}
        planName={planNameById["Basic"]}
        interval={interval}
        features={featuresByPlan["Basic"]}
        buttons={
          hideActions ||
          (onBasic && interval === currentInterval ? (
            <Badge color="gray" _style="light" text="Subscribed" />
          ) : (
            <DepictLink
              _style="Primary"
              onClick={() => changeTo?.("basic")}
              disabled={pending}
            >
              Choose{" "}
              {interval === AppPricingInterval.Annual ? "yearly" : "monthly"}
            </DepictLink>
          ))
        }
        active={onBasic && interval === currentInterval}
      />
      <Plan
        size="small"
        hidePrice={hideActions}
        monthlyPrice={planTypeToPrice["Essential"]}
        annualPrice={planTypeToAnnualPrice["Essential"]}
        planName={planNameById["Essential"]}
        interval={interval}
        features={featuresByPlan["Essential"]}
        buttons={
          hideActions || (
            <>
              {onEssentials && interval === currentInterval ? (
                <Badge color="gray" _style="light" text="Subscribed" />
              ) : (
                <DepictLink
                  _style="Primary"
                  onClick={() => changeTo?.("essentials")}
                  disabled={pending}
                >
                  Choose{" "}
                  {interval === AppPricingInterval.Annual
                    ? "yearly"
                    : "monthly"}
                </DepictLink>
              )}
            </>
          )
        }
        active={onEssentials && interval === currentInterval}
        customCss={[
          onEssentials &&
            ((theme) => css`
              background: ${theme.colors.bg["weak-50"]};
            `),
        ]}
      />

      <div
        css={[
          (theme) => css`
            padding: 16px;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: space-between;
            border: 1px solid ${theme.colors.stroke["soft-200"]};
          `,
          isMobile ||
            css`
              grid-column: span 3;
            `,
          customCssProPlan,
        ]}
      >
        <div
          css={css`
            display: flex;
            align-items: baseline;
            gap: 8px;
          `}
        >
          <h2
            css={[
              (theme) => theme.typography["label-s"],
              css`
                margin: 0;
              `,
            ]}
          >
            Pro
          </h2>
          <div
            css={[
              (theme) => theme.typography["label-xs"],
              (theme) => css`
                color: ${theme.colors.text["sub-600"]};
              `,
            ]}
          >
            For stores above 250k session/month
          </div>
        </div>
        {hideActions || (
          <DepictLink
            _style="Primary"
            href="https://depict.ai/contact"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get in touch
          </DepictLink>
        )}
      </div>
    </>
  );
}
