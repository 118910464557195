import { useMutation } from "@tanstack/react-query";
import useAuthorizedApi, {
  AuthorizedApi,
} from "../../../../helpers/hooks/app/useAuthorizedApi";

const postTrackInstallComplete = async (
  api: AuthorizedApi,
  merchantId: string
): Promise<void> => {
  await api.POST(`/settings/track/{merchant_id}/install`, {
    params: {
      path: {
        merchant_id: merchantId,
      },
    },
  });
};

const useTrackIngestionComplete = (merchantId: string | null | undefined) => {
  const { api } = useAuthorizedApi();

  const mutation = useMutation({
    mutationFn: async () => {
      if (!api || !merchantId) return;
      return await postTrackInstallComplete(api, merchantId);
    },
  });

  return {
    trackIngestionComplete: mutation.mutate,
  };
};

export default useTrackIngestionComplete;
