const WIDTHS = {
  "x-small": 80,
  small: 160,
  medium: 240,
  large: 480,
  "x-large": 960,
  "xx-large": 1440,
};

export function getShopifyImage(url: string, width: keyof typeof WIDTHS) {
  return url + "&width=" + encodeURIComponent(WIDTHS[width].toString());
}
