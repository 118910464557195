// Routes for lite router, needs to be in a separate file to avoid circular imports which make HMR performance bad, like
//  /src/lite/views/Collection/Collection.tsx -> /src/lite/views/ContentBlock/EditContentBlockModal.tsx -> /src/lite/views/ContentBlock/LinkToCollectionComponent.tsx -> /src/helpers/hooks/useStorefrontAPIClient.tsx -> /src/helpers/hooks/app/useAuthorizedApi.tsx -> /src/api/authorizedApi.ts -> /src/lite/DepictLiteRouter.tsx -> /src/lite/views/Collection/Collection.tsx

export enum ROUTES {
  HOME = "/",
  LOGIN = "/login",
  DEFAULT_REDIRECT = "/:merchantId",
  GETTING_STARTED = "/:merchantId/getting-started",
  GETTING_STARTED_STEP_1 = "/:merchantId/getting-started/step-1",
  GETTING_STARTED_STEP_2 = "/:merchantId/getting-started/step-2",
  COLLECTIONS = "/:merchantId/collections",
  COLLECTION = "/:merchantId/collections/:collectionId",
  COLLECTION_PREVIEW = "/:merchantId/collections/:collectionId/preview",
  SETTINGS = "/:merchantId/settings",
  MULTI_STORE_CONNECTIONS = "/:merchantId/settings/connections",
  MULTI_STORE_STORES = "/:merchantId/settings/stores",
  NO_MERCHANTS = "/no-merchants",
  NOT_FOUND = "/404",
  MAINTENANCE = "/maintenance",
  UNAUTHORIZED = "/unauthorized",
  GUIDES = "/:merchantId/guides",
}
