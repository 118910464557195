import * as React from "react";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import { CaretRight, Check } from "@phosphor-icons/react";
import { css, Interpolation, Theme, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { useIsDarkMode } from "../../helpers/hooks/app/useIsDarkMode";

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const DropdownMenuGroup = DropdownMenuPrimitive.Group;

const DropdownMenuPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuSub = DropdownMenuPrimitive.Sub;

const DropdownMenuRadioGroup = styled(DropdownMenuPrimitive.RadioGroup)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DropdownMenuSubTrigger = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubTrigger>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubTrigger> & {
    inset?: boolean;
  }
>(({ className, inset, children, ...props }, ref) => {
  return (
    <DropdownMenuPrimitive.SubTrigger ref={ref} {...props}>
      {children}
      <CaretRight className="ml-auto h-4 w-4" />
    </DropdownMenuPrimitive.SubTrigger>
  );
});
DropdownMenuSubTrigger.displayName =
  DropdownMenuPrimitive.SubTrigger.displayName;

const DropdownMenuSubContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.SubContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.SubContent>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.SubContent ref={ref} {...props} />
));
DropdownMenuSubContent.displayName =
  DropdownMenuPrimitive.SubContent.displayName;

const DropdownMenuContentWithoutPortal = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    extraCss?: Interpolation<Theme>;
  }
>(
  (
    {
      className,
      sideOffset = 4,
      side = "bottom",
      align = "center",
      extraCss,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const isDark = useIsDarkMode();

    return (
      <DropdownMenuPrimitive.Content
        ref={ref}
        sideOffset={sideOffset}
        side={side}
        align={align}
        css={[
          theme.typography["paragraph-s"],
          css`
            border-radius: 16px;
            background: ${theme.colors.bg["white-0"]};
            box-shadow: ${isDark
              ? theme.shadows.large
              : `0 16px 32px -12px rgba(14, 18, 27, 0.1)`};
            padding: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            min-width: 200px;
            z-index: calc(var(--dnd-base-z-index, 0) + 1000);

            animation: contentsEnter 95ms ease-out;

            &[data-state="closed"] {
              animation: contentsExit 95ms ease-out;
            }

            @keyframes contentsEnter {
              from {
                opacity: 0;
                transform: scale(0.96) translateY(-8px);
              }
              to {
                opacity: 1;
                transform: scale(1) translateY(0);
              }
            }

            @keyframes contentsExit {
              from {
                opacity: 1;
                transform: scale(1) translateY(0);
              }
              to {
                opacity: 0;
                transform: scale(0.96) translateY(-8px);
              }
            }
          `,
          extraCss,
        ]}
        {...props}
      />
    );
  }
);

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & {
    extraCss?: Interpolation<Theme>;
  }
>(
  (
    {
      className,
      sideOffset = 4,
      side = "bottom",
      align = "center",
      extraCss,
      ...props
    },
    ref
  ) => {
    return (
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuContentWithoutPortal
          ref={ref}
          side={side}
          align={align}
          sideOffset={sideOffset}
          extraCss={extraCss}
          {...props}
        />
      </DropdownMenuPrimitive.Portal>
    );
  }
);
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => {
  const theme = useTheme();
  return (
    <DropdownMenuPrimitive.Item
      ref={ref}
      css={css`
        padding: 8px;
        outline: none;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          background: ${theme.colors.bg["weak-50"]};
        }

        &[data-disabled] {
          cursor: default;
          color: ${theme.colors.text["disabled-300"]};
          &:hover {
            background: ${theme.colors.bg["white-0"]};
          }
      `}
      {...props}
    />
  );
});

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const DropdownMenuCheckboxItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>
>(({ className, children, checked, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem ref={ref} checked={checked} {...props}>
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <DropdownMenuPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
    {children}
  </DropdownMenuPrimitive.CheckboxItem>
));
DropdownMenuCheckboxItem.displayName =
  DropdownMenuPrimitive.CheckboxItem.displayName;

const DropdownMenuRadioItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem> & {
    extraCss?: Interpolation<Theme>;
    radioVerticalPosition?: "left" | "right";
    radioHorizontalPosition?: "top" | "bottom";
  }
>(
  (
    {
      className,
      children,
      extraCss,
      radioVerticalPosition,
      radioHorizontalPosition,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();

    const verticalPosition = radioVerticalPosition || "left";

    const indicatorCss = css`
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      width: 16px;
      height: 16px;
      border-radius: 50%;

      :after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${theme.colors.neutral.static.white};
      }
    `;
    return (
      <DropdownMenuPrimitive.RadioItem
        css={[
          css`
            position: relative;
            display: flex;
            cursor: pointer;
            user-select: none;
            align-items: center;
            padding: 8px;
            outline: none;
            border-radius: 8px;

            background: ${theme.colors.bg["white-0"]};

            &:hover {
              background: ${theme.colors.bg["weak-50"]};
            }

            &[data-disabled] {
              cursor: default;

              &:hover {
                background: ${theme.colors.bg["white-0"]};
              }
            }
          `,
          extraCss,
        ]}
        ref={ref}
        {...props}
      >
        <span
          css={css`
            position: absolute;
            ${verticalPosition}: 8px;
            ${radioHorizontalPosition || "top"}: ${radioHorizontalPosition
              ? "8px"
              : "unset"};

            width: 20px;
            height: 20px;

            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <div
            css={css`
              position: absolute;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              border: 2px solid ${theme.colors.bg["soft-200"]};
              box-shadow: 0 16px 32px -12px rgba(14, 18, 27, 0.1);
            `}
          />
          {props.disabled && (
            <div
              css={[
                indicatorCss,
                css`
                  position: absolute;
                  background: ${theme.colors.bg["soft-200"]};
                `,
              ]}
            />
          )}
          <DropdownMenuPrimitive.ItemIndicator
            css={[
              indicatorCss,
              css`
                background: ${theme.colors.primary.base};
              `,
            ]}
          />
        </span>
        <div
          css={[
            theme.typography["paragraph-s"],
            css`
              padding-left: ${verticalPosition === "left" ? "28px" : "unset"};
              padding-right: ${verticalPosition === "right" ? "28px" : "unset"};

              color: ${props.disabled
                ? theme.colors.text["soft-400"]
                : theme.colors.text["strong-950"]};
            `,
          ]}
        >
          {children}
        </div>
      </DropdownMenuPrimitive.RadioItem>
    );
  }
);
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName;

const DropdownMenuLabel = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Label> & {
    inset?: boolean;
    extraCss?: Interpolation<Theme>;
  }
>(({ className, inset, extraCss, ...props }, ref) => {
  const theme = useTheme();
  return (
    <DropdownMenuPrimitive.Label
      ref={ref}
      css={[
        theme.typography["subheading-xs"],
        css`
          color: ${theme.colors.text["soft-400"]};
          padding: 4px 8px;
        `,
        extraCss,
      ]}
      {...props}
    />
  );
});
DropdownMenuLabel.displayName = DropdownMenuPrimitive.Label.displayName;

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => {
  const theme = useTheme();
  return (
    <DropdownMenuPrimitive.Separator
      ref={ref}
      css={css`
        border-top: 1px solid ${theme.colors.stroke["soft-200"]};
      `}
      {...props}
    />
  );
});
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenuShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return <span {...props} />;
};
DropdownMenuShortcut.displayName = "DropdownMenuShortcut";

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuContentWithoutPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuRadioGroup,
};
