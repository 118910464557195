import { useQuery } from "@tanstack/react-query";
import { getLiteQueryKey, QueryId } from "../../queries";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import useAuthorizedApi, {
  AuthorizedApi,
} from "../../../helpers/hooks/app/useAuthorizedApi";
import { getData } from "../../../api/authorizedApi";
import { toTzAgnosticIsoDate } from "src/lite/helpers/datetime";

interface DashboardCollectionDataQueryParams {
  from_date: Date;
  to_date: Date;
}

const PAGE_SIZE = 30;

async function apiGetDashboardCollections({
  api,
  merchantId,
  from_date,
  to_date,
}: {
  api: AuthorizedApi;
  merchantId: string;
  from_date: string;
  to_date: string;
}) {
  return api.POST("/dashboard/top-collections", {
    params: {
      query: {
        merchant_id: merchantId,
      },
    },
    body: {
      from_date: from_date,
      to_date: to_date,
    },
  });
}

export default function useTopCollectionsData({
  from_date,
  to_date,
}: DashboardCollectionDataQueryParams) {
  const { merchantId } = useMerchantId();
  const { api } = useAuthorizedApi();

  const fromDateString = toTzAgnosticIsoDate(from_date);
  const toDateString = toTzAgnosticIsoDate(to_date);

  const queryKey = getLiteQueryKey(QueryId.GetDashboardCollectionData, {
    merchantId: merchantId,
    from_date: fromDateString,
    to_date: toDateString,
  });

  return useQuery({
    queryKey,
    queryFn: async () => {
      const res = await apiGetDashboardCollections({
        api,
        merchantId,
        from_date: fromDateString,
        to_date: toDateString,
      });

      return getData(res);
    },
  });
}
