import { useMerchantId } from "./useMerchantId";
import { Merchant } from "src/api/types";
import useOptionalMerchant from "./useOptionalMerchant";

interface UseMerchantReturn {
  merchant: Merchant | null;
  merchantOptions: Merchant[];
  merchantId: string;
  loading: boolean;
}

/**
 * useMerchant provides access to the selected merchant
 */
function useMerchant(): UseMerchantReturn {
  const optionalMerchant = useOptionalMerchant();
  const { merchantId } = useMerchantId();

  return {
    merchantId,
    merchant: optionalMerchant.merchant ?? null,
    merchantOptions: optionalMerchant.merchantOptions,
    loading: optionalMerchant.loading,
  };
}

export default useMerchant;
