import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { monitoringEnabled } from "src/monitoring";

interface Props {
  show: boolean;
}

const useShowIntercom = (props: Props) => {
  const { update } = useIntercom();

  useEffect(() => {
    if (!monitoringEnabled) return;
    if (props.show) {
      update({
        hideDefaultLauncher: false,
      });
    } else {
      update({
        hideDefaultLauncher: true,
      });
    }
  }, [props.show, update]);
};

export default useShowIntercom;
