import { Global, css } from "@emotion/react";

import fontBold from "/src/assets/fonts/neue-montreal/PPNeueMontreal-Bold.woff2?url";
import fontBook from "/src/assets/fonts/neue-montreal/PPNeueMontreal-Book.woff2?url";
import fontItalic from "/src/assets/fonts/neue-montreal/PPNeueMontreal-Italic.woff2?url";
import fontMedium from "/src/assets/fonts/neue-montreal/PPNeueMontreal-Medium.woff2?url";
import fontSemiboldItalic from "/src/assets/fonts/neue-montreal/PPNeueMontreal-SemiBoldItalic.woff2?url";
import fontThin from "/src/assets/fonts/neue-montreal/PPNeueMontreal-Thin.woff2?url";

export default function NeueMontreal() {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "Neue Montreal";
          src: url("${fontBold}") format("woff2");
          font-weight: bold;
          font-style: normal;
        }
        @font-face {
          font-family: "Neue Montreal";
          src: url("${fontBook}") format("woff2");
          font-weight: 400;
          font-style: normal;
        }
        @font-face {
          font-family: "Neue Montreal";
          src: url("${fontItalic}") format("woff2");
          font-weight: 400;
          font-style: italic;
        }
        @font-face {
          font-family: "Neue Montreal";
          src: url("${fontMedium}") format("woff2");
          font-weight: 500;
          font-style: normal;
        }
        @font-face {
          font-family: "Neue Montreal";
          src: url("${fontSemiboldItalic}") format("woff2");
          font-weight: 600;
          font-style: italic;
        }
        @font-face {
          font-family: "Neue Montreal";
          src: url("${fontThin}") format("woff2");
          font-weight: 100;
          font-style: normal;
        }
      `}
    />
  );
}
