import Banner from "../../../alignUI/Banner/Banner";
import { css, useTheme } from "@emotion/react";
import usePlanStatus from "../../helpers/usePlanStatus";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { WarningCircle } from "@phosphor-icons/react";
import { Link } from "react-router";
import { useSubscriptionStatus } from "../Settings/PlanSettings/useSubscriptionStatus";
import { Suspense } from "react";

const MaybeFrozenBanner = () => (
  <Suspense>
    <MaybeFrozenBannerInner />
  </Suspense>
);

const MaybeFrozenBannerInner = () => {
  const theme = useTheme();
  const {
    frozen,
    days_until_reset,
    num_active_collections,
    max_active_collections,
  } = usePlanStatus();
  const { isPaid } = useSubscriptionStatus();
  const { merchantId } = useMerchantId();

  if (!frozen) {
    return null;
  }

  if (!isPaid) {
    return (
      <Banner
        expand={true}
        status={"warning"}
        _style={"filled"}
        withIcon
        iconOverride={<WarningCircle />}
        title={"Unlimited trial expired"}
        description={`You have ${num_active_collections} out of ${max_active_collections} allowed active collections - collections are frozen`}
        component={
          <div
            css={css`
              @media (max-width: ${theme.breakpoints.sm}px) {
                margin-left: 0;
              }
            `}
          >
            <Link
              to={`/${merchantId}/settings`}
              css={css`
                white-space: nowrap;
                color: ${theme.colors.text["white-0"]};
              `}
            >
              Upgrade plan
            </Link>
            {" or "}
            <Link
              to={`/${merchantId}/settings?downgrade=1`}
              css={css`
                white-space: nowrap;
                color: ${theme.colors.text["white-0"]};
              `}
            >
              Deactivate collections
            </Link>
          </div>
        }
      />
    );
  }

  return (
    <Banner
      expand={true}
      status={"warning"}
      _style={"filled"}
      withIcon
      iconOverride={<WarningCircle />}
      title={"Monthly session limit exceeded"}
      description={`Functionality may be limited until session count resets in ${days_until_reset} days`}
      component={
        <div
          css={css`
            @media (max-width: ${theme.breakpoints.sm}px) {
              margin-left: 0;
            }
          `}
        >
          <Link
            to={`/${merchantId}/settings`}
            css={css`
              white-space: nowrap;
              color: ${theme.colors.text["white-0"]};
            `}
          >
            Check my plan
          </Link>
        </div>
      }
    />
  );
};

export default MaybeFrozenBanner;
