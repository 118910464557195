import { css, Interpolation, Theme, useTheme } from "@emotion/react";
import { ContentDivider } from "../ContentDivider/ContentDivider";
import {
  TabMenuVertical,
  TabMenuVerticalItem,
} from "../TabMenuVertical/TabMenuVertical";
import React from "react";
import { AppPricingInterval } from "../../generated/graphql-admin-api";
import { Badge } from "../Badges/Badge";
import { CheckCircle, Icon } from "@phosphor-icons/react";
import { PlanType } from "src/lite/helpers/plans";

export type PlanFeature = {
  icon: Icon;
  text: React.ReactNode;
  color?: "blue" | "red";
};

export type PlanProps = {
  planName: string;
  monthlyPrice: number;
  annualPrice: number;
  features: PlanFeature[];
  active?: boolean;
  interval?: AppPricingInterval;
  extra?: React.ReactNode;
  buttons?: React.ReactNode;
  size?: "small" | "large";
  customCss?: Interpolation<Theme>;
  hidePrice?: boolean;
  showSubscribedBadge?: boolean;
};

const unlimitedCollections: PlanFeature = {
  icon: CheckCircle,
  text: "Unlimited collections",
  color: undefined,
};

export const featuresByPlan: Record<PlanType, PlanFeature[]> = {
  free: [{ icon: CheckCircle, text: "Up to 3 collections", color: undefined }],
  Basic: [
    { icon: CheckCircle, text: "Up to 25k sessions/month", color: undefined },
    unlimitedCollections,
  ],
  Essential: [
    { icon: CheckCircle, text: "Up to 250k sessions/month", color: undefined },
    unlimitedCollections,
  ],
  Pro: [unlimitedCollections],
};

export function Plan({
  planName,
  monthlyPrice,
  annualPrice,
  features,
  interval = AppPricingInterval.Every_30Days,
  buttons,
  extra,
  active,
  size = "large",
  customCss,
  hidePrice,
  showSubscribedBadge,
}: PlanProps) {
  const theme = useTheme();
  return (
    <div
      css={[
        css`
          background: ${active
            ? theme.colors.bg["weak-50"]
            : theme.colors.bg["white-0"]};
          padding: 16px;
          border: 1px solid ${theme.colors.stroke["soft-200"]};
          border-radius: 10px;
          // For icons in dark mode
          color: ${theme.colors.text["strong-950"]};
        `,
        customCss,
      ]}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          height: 100%;
          justify-content: space-between;
        `}
      >
        <div
          css={[
            css`
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            `,
          ]}
        >
          <div
            css={[
              css`
                display: flex;
                flex-direction: column;
              `,
              size === "large"
                ? css`
                    row-gap: 16px;
                  `
                : css`
                    row-gap: 4px;
                  `,
            ]}
          >
            {" "}
            {/* Headings wrapper */}
            <div
              css={css`
                display: flex;
                column-gap: 16px;
                justify-content: space-between;
              `}
            >
              <h2
                css={[
                  size === "large"
                    ? theme.typography["label"]
                    : theme.typography["label-s"],
                  css`
                    margin: 0;
                  `,
                ]}
              >
                {planName}
              </h2>
              {extra}
            </div>
            {hidePrice || (
              <h3
                css={[
                  size === "large"
                    ? theme.typography.h3
                    : [
                        theme.typography["label-xs"],
                        css`
                          color: ${theme.colors.text["sub-600"]};
                          font-weight: 400;
                        `,
                      ],
                  css`
                    margin: 0;
                    display: flex;
                    align-items: center;
                  `,
                ]}
              >
                {"$"}
                {Math.round(
                  interval === AppPricingInterval.Annual
                    ? annualPrice
                    : monthlyPrice
                ).toFixed(0)}
                <span
                  css={[
                    theme.typography["label-xs"],
                    css`
                      color: ${theme.colors.text["sub-600"]};
                      font-weight: 400;
                    `,
                  ]}
                >
                  /{interval === AppPricingInterval.Annual ? "year" : "month"}
                </span>
                {interval === AppPricingInterval.Annual && annualPrice > 0 && (
                  <Badge
                    css={css`
                      margin-left: 4px;
                    `}
                    _style={"light"}
                    text={`SAVE $${monthlyPrice * 12 - annualPrice}`}
                    color={"green"}
                    size={"small"}
                  />
                )}
              </h3>
            )}
          </div>
          <ContentDivider />
          <TabMenuVertical>
            {features?.map(({ icon: Icon, text, color }, i) => (
              <TabMenuVerticalItem
                key={i}
                leftIcon={<Icon size={20} color={color} />}
                size={size}
                customCss={[
                  color === "red" &&
                    ((theme) => css`
                      color: ${theme.colors.state.error.base};
                    `),
                  color === "blue" &&
                    ((theme) => css`
                      color: ${theme.colors.primary.base};
                    `),
                ]}
              >
                {text}
              </TabMenuVerticalItem>
            ))}
          </TabMenuVertical>
        </div>
        <div
          css={css`
            align-self: end;
          `}
        >
          {buttons}
        </div>
        {showSubscribedBadge && (
          <div
            css={css`
              display: flex;
              justify-content: end;
            `}
          >
            <Badge _style="filled" color="gray" text="Suscribed" />
          </div>
        )}
      </div>
    </div>
  );
}
