import { breakpoints } from "src/theme";
import { useEffect, useMemo, useState } from "react";

const useIsMobile = (overrideSize?: number) => {
  const mediaQueryList = useMemo(
    () => window.matchMedia(`(width < ${overrideSize ?? breakpoints.sm}px)`),
    [overrideSize, breakpoints.sm]
  );
  const [isMobile, setIsMobile] = useState(mediaQueryList.matches);

  useEffect(() => {
    const handler = (e: MediaQueryListEvent) => setIsMobile(e.matches);
    mediaQueryList.addEventListener("change", handler);
    return () => mediaQueryList.removeEventListener("change", handler);
  }, [mediaQueryList]);

  return isMobile;
};

export default useIsMobile;
