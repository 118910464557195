import { Navigate, Outlet } from "react-router";
import useOptionalMerchant from "src/helpers/hooks/app/useOptionalMerchant";
import { useMaybeAppBridge } from "src/helpers/shopify/useMaybeAppBridge";
import { buildPathWithMerchantId } from "../helpers/buildPathWithMerchantId";
import { ROUTES } from "../DepictLiteRouter-ROUTES";
import { TRIGGER_INSTALL_PATH_MERCHANT_ID } from "../views/GettingStarted/Ingestion/IngestionStep";

export default function GettingStartedCompletedBoundary() {
  const { merchant } = useOptionalMerchant();
  const appBridge = useMaybeAppBridge();

  if (!merchant?.shopify_app_installed) {
    // There is no merchant with the app installed. Three possibilities:
    // 1. We are in embedded mode and the merchant has not installed the app, and should see the install flow.
    // 2. We are in superuser mode and the merchant has uninstalled the app, and should see whatever the merchant last saw.
    // 3. We are in embedded mode and the merchant is accessing a substore, but we have not loaded the multi-store data yet. Ideally they would see the substore screen, but we will have to show the install flow until we have the data.

    if (appBridge?.environment?.embedded) {
      // Case 1 and 3
      return (
        <Navigate
          replace
          to={buildPathWithMerchantId(
            ROUTES.GETTING_STARTED,
            TRIGGER_INSTALL_PATH_MERCHANT_ID
          )}
        />
      );
    }

    if (!merchant) {
      // Superuser is trying to access a merchant that straight up does not exist.
      // This case is actually covered in MerchantLoadedBoundary, but Typescript doesn't know that.
      return <Navigate to={ROUTES.NOT_FOUND} />;
    }
  }

  if (!merchant.has_run_at_least_one_ingestion) {
    // Merchant exists, but ingestion hasn't completed yet, navigate to ingestion progress bar
    return (
      <Navigate
        replace
        to={buildPathWithMerchantId(ROUTES.GETTING_STARTED, merchant.id)}
      />
    );
  }

  return <Outlet />;
}
