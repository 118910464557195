import { css, useTheme } from "@emotion/react";
import React from "react";

interface ContentDividerProps {
  text?: string;
  icon?: React.ReactNode;
  textBelow?: boolean;
}

export const ContentDivider = (props: ContentDividerProps) => {
  const theme = useTheme();

  if ((props.text && !props.textBelow) || props.icon) {
    return (
      <>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            width: 100%;
            height: fit-content;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: 1px;
              min-height: 1px;
              background-color: ${theme.colors.stroke["soft-200"]};
            `}
          />
          {props.icon && props.icon}
          {props.text && !props.textBelow && (
            <span
              css={[
                theme.typography["subheading-xxs"],
                css`
                  color: ${theme.colors.text["soft-400"]};
                  white-space: nowrap;
                `,
              ]}
            >
              {props.text}
            </span>
          )}
          <div
            css={css`
              width: 100%;
              height: 1px;
              min-height: 1px;
              background-color: ${theme.colors.stroke["soft-200"]};
            `}
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div
        css={css`
          width: 100%;
          height: 1px;
          min-height: 1px;
          background-color: ${theme.colors.stroke["soft-200"]};
        `}
      />
      {props.text && props.textBelow && (
        <div
          css={[
            theme.typography["label-xs"],
            css`
              color: ${theme.colors.text["soft-400"]};
              text-transform: uppercase;
              padding: 4px 8px;
            `,
          ]}
        >
          {props.text}
        </div>
      )}
    </>
  );
};
